<template>
  <div v-if="startTime" class="timer">
    <span>{{ remainingTimeText }}</span>
  </div>
</template>

<script>

export default {
  data() {
    return {
      startTime: null,
      limitTime: 0,
      elapsedTime: 0,
      remainingTime: 0,
      remainingTimeText: '--:--:--',
      timer: {},
      isCounting: false,
    }
  },
  methods: {
    created() {
      $(document).ready(()=>{
        this.start();
      });
    },
    async start(limitTime) {
      if (this.isCounting || limitTime === null) return;
      if (limitTime) {
        this.limitTime = limitTime;
        this.startTime = new Date();
      }

      let currentElapsedTime = Math.round((new Date() - this.startTime) / 1000);

      if (limitTime === undefined) {
        this.limitTime +=  currentElapsedTime - this.elapsedTime;
      }

      this.remainingTime = this.getRemainingTime(currentElapsedTime);
      this.remainingTimeText = this.calculateTime(this.remainingTime);
      this.timer = setInterval(() => {
        if (this.remainingTime<1) {
          clearInterval(this.timer);
          this.$emit('timeOver');
        } else {
          this.elapsedTime = Math.round((new Date() - this.startTime) / 1000);
          this.remainingTime = this.limitTime - this.elapsedTime;
          this.remainingTimeText = this.calculateTime(this.remainingTime);
        }
      }, 1000);
      this.isCounting = true;
    },
    getRemainingTime(currentElapsedTime=null) {
      return this.limitTime - (currentElapsedTime ? currentElapsedTime : this.elapsedTime);
    },
    calculateTime(time) {
      if (time === 0) return '--:--:--';
      let second = 0;
      let minute = 0;
      let hour = 0; 

      hour = parseInt(time/3600) ; // 转小时
      minute = parseInt(time%3600/60); // 转分钟
      second = parseInt(time%3600%60); // 转秒 
      // 格式化
      hour = (hour > 9 ? hour : "0" + hour) + "";
      minute = (minute > 9 ? minute : "0" + minute) + "";
      second = (second > 9 ? second : "0" + second) + "";
                 
      return hour + ":" + minute + ":" + second;
    },
    reset() {
      clearInterval(this.timer);
      this.timer = {};
      this.startTime = null;
      this.limitTime = 0;
      this.elapsedTime = 0;
      this.remainingTime = 0;
      this.remainingTimeText = '--:--:--';
      this.isCounting = false;
    },
    pause() {
      clearInterval(this.timer);
      this.isCounting = false;
    },
    restart(limitTime) {
      this.start(limitTime);
    }
  }
}
</script>

<style lang="scss" scoped>

.timer {
  background-color: white;
  padding: 0 2vw;
  height: 60%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ef5350;
  font-weight: bold;
  font-size: 15px;
  // font-family: '-webkit-pictograph';
}

</style>
