<template>
  <div class="question-container" :class="hide ? 'hidden-page' : ''">
    <div class="question-header">
      <div class="question-number">
        <!-- <span class="material-icons margin-right-5">quiz</span>
        <span>Q&nbsp;{{ getQuestionLabel(questionNumber) }}&nbsp;&nbsp;</span> -->
        <span class="material-icons margin-right-5">quiz</span>
        <span>Q&nbsp;{{ questionNumbers[0] }}{{ mergeGroupQuestions ? `-${Math.max(...questionNumbers)}` : ''
          }}&nbsp;/&nbsp;{{ questionTotalAmount }}</span>
      </div>
    </div>
    <div v-if="testDirection" class="direction-botton" :class="{ 'z-index-minus-1': $parent.isQuestionListOpen }">
      <a class="waves-effect waves-light btn modal-trigger" href="#direction-modal">DIRECTIONS
      </a>
    </div>
    <div v-if="question && mode == 'practice'" class="margin-bottom-5">
      <div v-if="question.question_group && question.question_group.question_group_audios" class="audio-container"
        :class="question.question_group.question_group_audios.length > 1 ? 'justify-content-space-between' : 'justify-content-center'">
        <audio v-for="(audio, index) in question.question_group.question_group_audios" :key="index"
          :id="`audio_group_${index}`" class="audio" :src="getUrl(audio.path)" controls controlsList="nodownload"
          type="audio/mpeg" @play="stopOtherAudios($event)" preload="auto" />
      </div>
      <div v-if="part.code !== 'p2' && !question.question_group && question.question_audios" class="audio-container"
        :class="question.question_audios.length > 1 ? 'justify-content-space-between' : 'justify-content-center'">
        <audio v-for="(audio, index) in question.question_audios" :key="index" :id="`audio_${index}`" class="audio"
          :src="getUrl(audio.path)" controls controlsList="nodownload" type="audio/mpeg" @play="stopOtherAudios($event)"
          preload="auto" />
      </div>
    </div>
    <div v-if="!question" class="no-data-warning" v-html="$t('relevantDataNotFound')"></div>
    <div v-else-if="part.code === 'p1'" class="question-content" :class="`part-${part.code}`">
      <div v-if="question" class="d-flex align-items-start flex-direction-column">
        <div class="d-flex align-items-start d-flex align-items-start margin-bottom-5">
          <span class="font-weight-bold">{{ question.display_number_1 }}.&nbsp;</span>
          <img v-for="(image, index) in question.question_images" :key="index" :id="`image_${index}`" class="image"
            :src="getUrl(image.path)" />
        </div>
        <QuestionText :key="questionNumbers[0]" :questionNumber="questionNumbers[0]" :displayNumber="''"
          :question="question" />
      </div>
      <div v-if="question && question.question_images.length === 0" class="no-data-warning"> 該当問題の画像ファイルが見つかりません。 </div>
    </div>
    <div v-else-if="part.code === 'p2'" class="question-content" :class="`part-${part.code}`">
      <!-- <ol :start="firstQuestionNumberOfCurrPart"> -->
      <div v-for="(row, index) in part.question_amount" :set="q = firstQuestionNumberOfCurrPart + row - 1" :key="index">
        <div class="d-flex align-items-start">
          <!-- <div><span class="font-weight-bold">{{ questions[q - 1].display_number_1 }}.&nbsp;</span></div> -->
          <div>
            <div v-if="mode == 'practice' && questions[q - 1].question_audios" class="audio-container"
              :class="questions[q - 1].question_audios.length > 1 ? 'justify-content-space-between' : 'justify-content-center'">
              <audio v-for="(audio, index) in questions[q - 1].question_audios" :key="index"
                :id="`audio_Q${q}_${index}`" class="audio" :src="getUrl(audio.path)" controls controlsList="nodownload"
                type="audio/mpeg" @play="stopOtherAudios($event)" preload="auto" />
            </div>
            <QuestionText :key="q" :questionNumber="q" :displayNumber="`${questions[q - 1].display_number_1}.&nbsp;`"
              :question="question" />
            <!-- <span class="font-weight-bold">Mark your answer on your answer
              sheet.</span> -->
          </div>
        </div>
      </div>
      <!-- </ol> -->
    </div>
    <div v-else-if="(part.code === 'p3' || part.code === 'p4') && question.question_group" class="question-content"
      :class="`part-${part.code}`">
      <div
        v-if="question.question_group.question_group_images && question.question_group.question_group_images.length > 0"
        class="group-question-materials" ref="group-question-materials">
        <img v-for="(image, index) in question.question_group.question_group_images" :key="index" :id="`image_${index}`"
          class="image" :src="getUrl(image.path)" />
      </div>
      <!-- show all questions in the group at once -->
      <div class="group-question-container" ref="group-question-container"
        v-for="row in (question.question_group.numberOfQuestion)"
        :set="q = question.question_group.firstQuestion + row - 1">
        <div v-if="mode == 'practice' && questions[q - 1].question_audios" class="audio-container"
          :class="questions[q - 1].question_audios.length > 1 ? 'justify-content-space-between' : 'justify-content-center'">
          <audio v-for="(audio, index) in questions[q - 1].question_audios" :key="index" :id="`audio_Q${q}_${index}`"
            class="audio" :src="getUrl(audio.path)" controls controlsList="nodownload" type="audio/mpeg"
            @play="stopOtherAudios($event)" preload="auto" />
        </div>
        <QuestionText :key="'Q' + (question.question_group.firstQuestion + row - 1)"
          :questionNumber="question.question_group.firstQuestion + row - 1"
          :question="questions[question.question_group.firstQuestion + row - 2]"
          :displayNumber="`${questions[question.question_group.firstQuestion + row - 2].display_number_1}.&nbsp;`">
        </QuestionText>
      </div>
    </div>
    <div v-else-if="part.code === 'p5'" class="question-content" :class="`part-${part.code}`">
      <div v-for="(question, questionIndex) in selectedQuestions" :key="questionIndex"
        :set="newQuestionNumber = questionNumbers[questionIndex]">
        <QuestionText :key="'Q' + newQuestionNumber" :questionNumber="newQuestionNumber" :question="question"
          :displayNumber="`${question.display_number_1}.&nbsp;`" />
      </div>
    </div>
    <div v-else-if="(part.code === 'p6' || part.code === 'p7') && question.question_group" class="question-content"
      :class="`part-${part.code}`">
      <div v-if="question && question.question_group.text" class="question-group-text group-question-materials"
        v-html="question.question_group.text" ref="group-question-materials"></div>
      <div v-else class="no-data-warning">該当問題の文章データが見つかりません。</div>
      <div class="group-question-container" ref="group-question-container">
        <QuestionText v-for="row in (question.question_group.numberOfQuestion)"
          :key="'Q' + (question.question_group.firstQuestion + row - 1)"
          :questionNumber="question.question_group.firstQuestion + row - 1"
          :question="questions[question.question_group.firstQuestion + row - 2]" :testLanguageCode="testLanguageCode"
          :displayNumber="`${questions[question.question_group.firstQuestion + row - 2].display_number_1}.&nbsp;`" />
      </div>
    </div>
    <div v-else class="question-content" :class="`part-${part.code}`">
      <div v-if="mode == 'practice' && question.question_group && question.question_audios" class="audio-container"
        :class="question.question_audios.length > 1 ? 'justify-content-space-between' : 'justify-content-center'">
        <audio v-for="(audio, index) in question.question_audios" :key="index" :id="`audio_Q${questionNumber}_${index}`"
          class="audio" :src="getUrl(audio.path)" controls controlsList="nodownload" type="audio/mpeg"
          @play="stopOtherAudios($event)" preload="auto" />
      </div>
      <p>
        <span>{{ questionNumber }}.&nbsp;</span>
        <span v-html="question.text"></span>
      </p>
      <p v-for="(questionChoice, index) in question.question_choices" :key="index">
        <label>
          <input :name="`question-choice-${index}`" type="radio">
          <span>{{ String.fromCharCode(index + 65) }}.&nbsp;&nbsp;</span>
          <span v-html="questionChoice.text"></span>
        </label>
      </p>
    </div>
    <div v-if="testDirection" id="direction-modal" class="direction-modal modal">
      <div class="modal-content" v-html="testDirection.text"></div>
    </div>
  </div>
</template>

<script>
import QuestionText from './QuestionText';
import helpers from '../../js/utils/helpers';

export default {
  components: {
    QuestionText
  },
  props: {
    groupQuestionDisplayMethod: String,
    question: Object,
    questionNumber: Number,
    questionTotalAmount: Number,
    firstQuestionNumberOfCurrPart: Number,
    testLanguageCode: String,
    testDirection: Object,
    part: Object,
    questions: Array,
    mode: String,
    hide: Boolean,
  },
  data() {
    return {
      isGroupQuestion: false,
      questionNumbers: [],
      selectedQuestions: [],
      answers: [],
    };
  },
  created() {
    this.init();
    let self = this;
    window.onresize = () => {
      self.setGroupQuestionMaterialMaxHeight();
    };
  },
  mounted() {
  },
  methods: {
    init() {
      this.isGroupQuestion = this.question && this.question.question_group_id > 0;
      if (this.mergeGroupQuestions) {
        let i = 0;
        this.selectedQuestions = this.questions.filter((question, index) => {
          if (question.question_group_id == this.question.question_group_id) {
            let questionNumber = index + 1;
            this.questionNumbers.push(questionNumber);
            this.answers[i] = this.$parent.answers[questionNumber - 1];
            i++;
            return true;
          }
        });
      } else {
        this.selectedQuestions = [this.question];
        this.questionNumbers.push(this.questionNumber);
        this.answers[0] = this.$parent.answers[this.questionNumber - 1];
      }
      this.$parent.isFirstQuestion = this.questionNumbers[0] <= 1;
      this.$parent.isLastQuestion = this.questionNumbers[this.questionNumbers.length - 1] >= this.questionTotalAmount;

      if (this.question && this.mode === "practice")
        this.stopAudios();

      $(document).ready(() => {
        this.setDynamicQuestionNumber();
        this.setGroupQuestionMaterialMaxHeight();
      });

      let self = this;
      $(document).ready(async function () {
        $('#direction-modal').modal();
        await helpers.sleep(500);
        self.$parent.openDirection();
      });
    },
    getUrl(path) {
      return helpers.getUrl(path);
    },
    stopOtherAudios(e) {
      for (let audio of $('audio')) {
        if (audio) {
          if (audio.id === e.target.id) continue;
          audio.pause();
          audio.currentTime = 0;
        }
      }
    },
    stopAudios() {
      for (let audio of $('audio')) {
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
        }
      }
    },
    setGroupQuestionMaterialMaxHeight() {
      $(".group-question-materials").css({ "max-height": `${$(".question-content")[0] ? $(".question-content")[0].offsetHeight * 0.7 : 0}px` });
    },
    setDynamicQuestionNumber() {
      let groupedQuestions = this.selectedQuestions;
      $('.dynamic-question-number').each(function (index, element) {
        let dataQuestionIndex = $(element).attr('data-question-index');
        let questionIndex;
        switch (dataQuestionIndex) {
          case 'first':
            questionIndex = 0;
            break;
          case 'last':
            questionIndex = groupedQuestions.length - 1;
            break;
          default:
            questionIndex = dataQuestionIndex - 1;
        }
        $(element).text(groupedQuestions[questionIndex].display_number_1);
      });
    }
  },
  computed: {
    mergeGroupQuestions() {
      if (!(this.question.question_group && this.question.question_group.options)) return false;
      let groupQuestionDisplayMethod = this.question.question_group.options.group_question_display_method;
      return this.isGroupQuestion && (groupQuestionDisplayMethod === 'same-page' || (!groupQuestionDisplayMethod && this.groupQuestionDisplayMethod === 'same-page'));
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.question-container {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 1rem;

  .question-header {
    display: flex;
    max-height: 10%;
    align-items: center;
    margin-bottom: 2em;

    .question-number {
      display: none;
      // display: flex;
      align-items: center;
      margin-top: 2vh;
      margin-bottom: 2vh;

      span {
        color: #ef5350;
        font-size: 2rem;
        white-space: nowrap;
      }
    }

    .question-index {
      span {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }

  .audio-container {
    display: contents;

    .audio {
      height: 3vh;
      width: 100%;
      margin: 0, 10px;
    }
  }

  .question-content {
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-left: auto;
      margin-right: auto;
    }

    &.part-p2 {
      align-items: flex-start;
      line-height: 2;
    }

    &.part-p5 {
      align-items: flex-start;
    }

    ol {
      margin: unset;
    }

    ol>li::marker {
      font-weight: bold;
    }
  }

  .group-question-materials {
    flex-shrink: 0;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 70%;
    margin-bottom: 1vh;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .image {
    object-fit: contain;
    max-height: 600px;
    max-width: 90%;
  }

  .group-question-container {
    // overflow: auto;
    width: 100%;
  }

  .no-data-warning {
    font-size: 2rem;
    line-height: 10;
  }
}
</style>
<style lang="scss">
.message-chain {
  .message-chain-chatbox {
    &.left {
      &:after {
        background-image: url('../../../public/assets/images/man.png');
      }
    }

    &.right {
      &:after {
        background-image: url('../../../public/assets/images/woman.png');
      }
    }
  }
}
</style>