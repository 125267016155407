<template>
  <div>
    <div v-if="!isFetchingData && !isLoadingAudios">
      <div v-if="testStarted" class="test-container">
        <div v-if="mode == 'test' && audioUrlList.length > 0 && testCode !== 'jft-basic'"
          class="overall-audio-container">
          <audio ref="overall-audio" class="overall-audio"
            :src="getUrl(audioUrlList[playingQuestionIndex][playingAudioIndex])" controlsList="nodownload"
            type="audio/mpeg" preload="auto" />
        </div>
        <Question v-show="questions.length > 0" :key="inQuestionNumber" ref="question" :testLevelCode="testLevelCode"
          :testDirection="testDirection" :questions="questions" :question="questions[inQuestionNumber - 1]"
          :questionNumber="inQuestionNumber" :questionTotalAmount="questions.length"
          :testLanguageCode="testLanguageCode" :mode="mode" :displayRuby="displayRuby"
          :groupQuestionDisplayMethod="groupQuestionDisplayMethod"
          :correctAnswerDisplay="onlineTestSection.options.correct_answer_display" />
        <Footer :questions="questions" ref="footer" @submit="commit()" />
        <template v-if="mode === 'practice'">
          <div class="side-button" name="backToMenuPage" style="bottom:10vh;">
            <a class="btn yellow"><i class="material-icons">home</i></a>
            <span class="text" @click="isSideButtonAlive['backToMenuPage'] ? swalBackToMenuPage($event) : ''"
              v-html="$t('backToMenuPage')"></span>
          </div>
          <div class="side-button" name="temporarilySave" style="bottom:20vh;">
            <a class="btn blue"><i class="material-icons">save</i></a>
            <span class="text" @click="isSideButtonAlive['temporarilySave'] ? temporarilySave($event) : ''"
              v-html="$t('temporarilySave')"></span>
          </div>
          <div v-if="onlineTestSection.time > 0" class="side-button" name="pause" style="bottom:30vh;">
            <a class="btn green"><i class="material-icons" style="width:1em;">{{ testPaused ? 'play_circle_filled' :
      'pause_circle_filled' }}e</i></a>
            <span class="text" @click="isSideButtonAlive['pause'] ? switchTimer($event) : ''"
              v-html="testPaused ? $t('restartTest') : $t('pause')"></span>
          </div>
        </template>
      </div>
    </div>
    <div v-else class="fetching-data">
      <PreloaderCircle />
    </div>
  </div>
</template>

<script>
import Question from './Question.vue';
import Footer from './Footer.vue';
import PreloaderCircle from '../base/PreloaderCircle';
import helpers from '../../js/utils/helpers';
import { json } from 'body-parser';

export default {
  components: {
    Question,
    Footer,
    PreloaderCircle
  },
  props: {
    onlineTestId: Number,
    onlineTestSectionId: [Number, String],
    onlineTestSection: Object,
    testCode: String,
    testDirections: Array,
    testLevelCode: String,
    testLanguageCode: String,
    privilegeType: String,
    mode: String,
    sectionStartTimer: Boolean,
    sectionStartStandbyTime: Number,
    displayRuby: [Boolean, String],
    groupQuestionDisplayMethod: String,
    choiceArrangement: String,
    restoreFromSaveData: Boolean,
    saveDataName: String,
    saveData: Object,
    commitImmediately: Boolean,
    isIOS: Boolean,
  },
  data() {
    return {
      testStarted: false,
      testPaused: false,
      questions: [],
      questionTagCounts: [],
      questionTags: [],
      audioUrlList: [],
      totalAudioNum: 0,
      loadedAudioNum: 0,
      answers: [],
      inQuestionNumber: 1,
      isFetchingData: true,
      isResultCommitted: false,
      isTeacher: false,
      sideMenuButton: {},
      playingQuestionIndex: 0,
      playingAudioIndex: 0,
      isQuestionListOpen: false,
      saveDataInterval: null,
      isFirstQuestion: true,
      isLastQuestion: false,
      openedTestDirections: [],
      isSideButtonAlive: {
        "backToMenuPage": false,
        "temporarilySave": false,
        "pause": false
      },
    }
  },
  created() {
    this.init();
    $('head').append('<style type="text/css">strong{font-weight:bold}</style>');
  },
  methods: {
    async init() {
      this.isFetchingData = true;
      this.testStarted = false;

      this.isTeacher = API.LMSGetValue('isTeacher');
      let contentActivity;
      let onlineTestSectionActivity;

      let coursePrivilegeTime = API.LMSGetValue('coursePrivilegeTime');
      if (!this.isTeacher) {
        contentActivity = coursePrivilegeTime.content_activity;
        if (contentActivity)
          onlineTestSectionActivity = contentActivity.online_test_activity.online_test_section_activities.find(onlineTestSectionActivity => {
            return onlineTestSectionActivity.online_test_section_id == this.onlineTestSectionId;
          });

        if (!onlineTestSectionActivity) {
          let others = {
            testLanguageCode: this.testLanguageCode,
            displayRuby: this.displayRuby,
            choiceArrangement: this.choiceArrangement,
            remainingTime: this.$parent.getRemainingTime()
          }
          let data = await API.fetchOnlineTestSectionActivity(this.onlineTestSectionId, this.mode, others);
          contentActivity = data.content_activity;
          onlineTestSectionActivity = data.online_test_section_activity;
        }

        API.LMSSetValue('contentActivity', contentActivity);
        API.LMSSetValue('onlineTestSectionActivity', onlineTestSectionActivity);
      }

      let temporarilySaveData = onlineTestSectionActivity && onlineTestSectionActivity.progress_text && onlineTestSectionActivity.progress_text.temporarily_save_data
        ? onlineTestSectionActivity.progress_text.temporarily_save_data
        : null;

      if (temporarilySaveData) {
        await new Promise(resolve => {
          swal({
            content: $(`<div>${this.$t('temporarilySavedDataFound')}</div>`)[0],
            buttons: {
              cancel: {
                text: "データを削除する",
                value: false,
                visible: true,
                className: "red white-text",
                closeModal: true,
              },
              confirm: {
                text: "続ける",
                value: true,
                visible: true,
                className: "",
                closeModal: true
              }
            },
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then(async response => {
            if (response) {
              new Promise(resolveInside => {
                this.$parent.restoreFromSaveData = true;
                this.$parent.saveData = temporarilySaveData;
                resolveInside();
              }).then(response => {
                // this.startFromSaveData();
                resolve();
              });
            } else {
              await API.removeTemporarilySavedData(this.onlineTestSectionId);
              resolve();
            }
          });
          $('.swal-button--cancel').html(this.$t('deleteData'));
          $('.swal-button--confirm').html(this.$t('useTemporarilySavedData'));
        });
      }

      await this.fetchQuestions();
      if (this.questions.error) return;
      this.handleAudios();

      if (this.restoreFromSaveData) {
        this.openedTestDirections = this.saveData.openedTestDirections;
        this.testParts = this.saveData.testParts;
      }

      this.isFetchingData = false;

      while (this.isLoadingAudios) {
        await helpers.sleep(500);
      }
      await this.startTest();

      this.$parent.sectionStartStandbyTime = API.LMSGetValue('sectionStartStandbyTime');
      if (this.onlineTestSection.time_calculation_method === "share" && this.$parent.sharedTestTime === null) this.$parent.sharedTestTime = window.API.getProgressText() && window.API.getProgressText().remaining_time ? window.API.getProgressText().remaining_time : this.onlineTestSection.time;
      if (this.testStarted) {
        this.$parent.startTimer(
          this.restoreFromSaveData
            ? this.saveData.remainingTime
            : (this.onlineTestSection.time_calculation_method === "share"
              ? this.$parent.sharedTestTime
              : this.onlineTestSection.time
            )
        );
        this.startOverallAudio(this.restoreFromSaveData ? this.saveData.playingAudioCurrentTime : 0);
        if (this.restoreFromSaveData && this.$refs.question.selectedQuestions[this.playingQuestionIndex].audioStarted && this.$refs.question.selectedQuestions[this.playingQuestionIndex].audioStarted[this.playingAudioIndex])
          this.$refs.question.startAudio(this.playingQuestionIndex, this.playingAudioIndex, this.saveData.playingAudioCurrentTime);

        let self = this;
        $(document).ready(() => {
          $('.side-button').mouseenter(function (event) {
            let name = $(this).attr("name");
            setTimeout(() => {
              self.isSideButtonAlive[name] = true;
            }, 500);
          });
        });
        $(document).ready(() => {
          $('.side-button').mouseleave(function (event) {
            let name = $(this).attr("name");
            self.isSideButtonAlive[name] = false;
          });
        });
      } else {
        this.$emit('backToMenuPage');
      }

      $('html').scrollTop(0);

      $(document).ready(() => {
        this.setSaveData();
        this.saveDataInterval = setInterval(() => {
          this.setSaveData();
        }, 5000, self = this);
      });
    },
    swalBackToMenuPage(event) {
      this.$emit('close');
    },
    getUrl(path) {
      return helpers.getUrl(path);
    },
    async failToFetchQuestions(data) {
      switch (data.error) {
        case 'no-referred-content-activities':
          await new Promise(resolve => {
            swal({
              content: $(`<span>${this.$t("noreferrableExamHistories")}</span>`)[0],
              icon: "error",
              button: this.$t("backToMenuPage"),
              closeOnClickOutside: false,
              closeOnEsc: false,
            }).then(response => {
              this.backToMenuPage();
              resolve();
            });
            $('.swal-button--confirm').html("メニューページへ<ruby>戻<rp>(</rp><rt>もど</rt><rp>)</rp></ruby>る");
          });
          break;
      }
    },
    async fetchQuestions() {
      let onlineTestSectionActivity = API.LMSGetValue('onlineTestSectionActivity');
      if (this.restoreFromSaveData && !this.saveData.isFetchingQuestions) {
        let questionData = JSON.parse(onlineTestSectionActivity.progress_text.question_data);
        this.questionTagCounts = this.saveData.questionTagCounts;
        this.questions = await API.fetchQuestionsById(questionData.question_ids, questionData.online_test_question_ids);
        if (Array.isArray(questionData.question_tag_ids)) this.questionTags = await API.fetchQuestionTagsById(questionData.question_tag_ids);
      } else if (onlineTestSectionActivity && onlineTestSectionActivity.progress_text && onlineTestSectionActivity.progress_text.question_data) {
        let questionData = JSON.parse(onlineTestSectionActivity.progress_text.question_data);
        this.questionTagCounts = questionData.question_tag_counts;
        this.questions = await API.fetchQuestionsById(questionData.question_ids, questionData.online_test_question_ids);
        if (Array.isArray(questionData.question_tag_ids)) this.questionTags = await API.fetchQuestionTagsById(questionData.question_tag_ids);
      } else {
        let questionData = await API.fetchQuestions(this.onlineTestSectionId, this.$parent.mode, this.choiceArrangement);
        if (questionData.status === 'fail') {
          this.questions = questionData;
          await this.failToFetchQuestions(questionData);
        } else {
          this.questions = questionData.questions;
          this.questionTagCounts = questionData.question_tag_counts;
          this.questionTags = questionData.question_tags;
          this.questions.forEach(question => {
            let audioAmount = question && question.question_audios ? question.question_audios.length : 0;
            if (audioAmount > 0) {
              question.audioPlayedTimes = {};
              question.audioStarted = {};
              for (let i = 0; i < audioAmount; i++) {
                question.audioPlayedTimes[i] = 0;
                question.audioStarted[i] = false;
                question.playingAudioIndex = 0;
                question.playingAudioCurrentTime = 0;
              }
            }
          });
          this.questions = JSON.parse(JSON.stringify(this.questions));
        }
      }
    },
    handleAudios() {
      let self = this;
      this.questions.forEach((question) => {
        let questionAudioArray = [];
        if (question && question.question_audios) {
          question.question_audios.forEach((audio) => {
            questionAudioArray.push(this.getAudioUrl(audio));
            self.totalAudioNum += 1;
          });
          if (questionAudioArray.length > 0) self.audioUrlList.push(questionAudioArray);
        }
      });
      if (!this.isIOS && this.audioUrlList.length > 0) this.preloadAudioRecursively(0, 0);
    },
    preloadAudioRecursively(questionIndex, audioIndex) {
      let url = helpers.getUrl(this.audioUrlList[questionIndex][audioIndex]);
      let self = this;
      let player = new Audio(url);
      player.addEventListener('canplaythrough', function (e) {
        self.loadedAudioNum += 1;
        if (audioIndex < self.audioUrlList[questionIndex].length - 1) {
          self.preloadAudioRecursively(questionIndex, audioIndex + 1);
        } else if (questionIndex < self.audioUrlList.length - 1) {
          self.preloadAudioRecursively(questionIndex + 1, 0);
        }
      });
      if (this.isIOS) player.load();
    },
    async startTest() {
      if (this.restoreFromSaveData) {
        if (this.saveData.isResultCommitted) await this.$parent.switchToNextTestSection(this.saveData.onlineTestSectionId, null, 1);
        else if (this.saveData.isFetchingQuestions || !this.saveData.testStarted) await this.showStartPanel();
        else await this.startFromSaveData();
      } else await this.showStartPanel();
    },
    startNextSection() {

    },
    async showStartPanel() {
      this.answers = Array(this.questions.length).fill('');
      if (this.mode == 'practice') {
        await swal(this.$t('wantToStartNTest', { n: this.onlineTestSection.name }), {
          buttons: [this.$t('no'), this.$t('yes')],
          closeOnClickOutside: false,
          closeOnEsc: false
        }).then(response => {
          if (response) {
            this.testStarted = true;
          } else {
            this.testStarted = false;
            this.backToMenuPage();
          }
        });
      } else {
        let title = this.$t('wantToStartNTest', { n: this.onlineTestSection.name });
        if (this.sectionStartTimer) await helpers.swalCountToStart(
          this.restoreFromSaveData && !this.saveData.isFetchingQuestions
            ? this.saveData.sectionStartStandbyTime
            : this.sectionStartStandbyTime,
          title,
          'automaticallyStartAfterNSeconds',
          this.$t('startImmediately'),
          this
        );
        else {
          await swal(
            {
              title,
              text: this.$t('clickButtonToStart'),
              icon: 'warning',
              button: this.$t('start'),
              closeOnClickOutside: false,
              closeOnEsc: false,
            }
          )
        }
        this.testStarted = true;
      }
    },
    async startFromSaveData() {
      this.answers = this.saveData.answers;
      this.inQuestionNumber = this.saveData.inQuestionNumber;
      this.playingQuestionIndex = this.saveData.playingQuestionIndex;
      this.playingAudioIndex = this.saveData.playingAudioIndex;
      if (this.commitImmediately)
        await swal({
          text: this.$t('expirationDatePassed') + this.$t('pleaseSelectSendingData'),
          buttons: {
            cancel: {
              text: this.$t('deleteData'),
              value: false,
              visible: true,
              className: "red white-text",
              closeModal: true,
            },
            confirm: {
              text: this.$t('sendData'),
              value: true,
              visible: true,
              className: "",
              closeModal: true
            }
          },
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then(response => {
          if (response) this.commit();
          else localStorage.removeItem(this.saveDataName);
        });
      else this.testStarted = true;
    },
    startOverallAudio(currentTime) {
      $(document).ready(() => {
        if (this.$refs['overall-audio']) {
          this.$refs['overall-audio'].currentTime = currentTime;
          this.audioPlay();
          this.$refs['overall-audio'].addEventListener('ended', this.audioEndedHandler, false);
          this.$refs['overall-audio'].addEventListener('pause', this.audioPauseHandler, false);
        }
        setTimeout(() => {
          if ($('.fixed-action-btn')[0]) this.sideMenuButton = $('.fixed-action-btn')[0].M_FloatingActionButton;
        }, 1000);
      });
    },
    async temporarilySave() {
      let responseData = await API.temporarilySave(this.getTemporarilySaveData());
      if (responseData.success) swal({
        content: $(`<span>${this.$t('dataTemporarilySaved')}</span>`)[0],
      }); else swal({
        content: $(`<span>${responseData.message ?? this.$t('internetDisconnected')}</span>`)[0],
        icon: "error"
      });
    },
    async commit() {
      this.isResultCommitted = false;
      this.$emit('pauseTimer');
      let result = this.getResult(this.questions, this.answers);
      let response = {};

      response = await API.LMSCommit(
        [API.LMSGetValue('onlineTestSectionActivity').id],
        this.mode,
        [result],
        { testLanguageCode: this.testLanguageCode, displayRuby: this.displayRuby, choiceArrangement: this.choiceArrangement, remainingTime: this.$parent.getRemainingTime() }
      );

      if (response.success) {
        this.isResultCommitted = true;
        this.$refs.question.stopAudios();
        if (this.$refs['overall-audio']) {
          this.$refs['overall-audio'].removeEventListener('ended', this.audioEndedHandler);
          this.$refs['overall-audio'].removeEventListener('pause', this.audioPauseHandler);
          this.$refs['overall-audio'].pause();
        }
        if (this.mode === 'practice' || !this.$parent.checkNextTestSection(this.onlineTestSection.id)) {
          clearInterval(this.saveDataInterval);
          localStorage.removeItem(this.saveDataName);
        } else this.setSaveData();
        swal({
          content: $(`<span class="line-height-5">${this.$t('testResultsSent')}</span>`)[0],
          icon: 'success',
          closeOnClickOutside: false,
          closeOnEsc: false,
          timer: 10000
        }).then(async () => {
          if (this.mode === 'test') {
            this.$emit('switchToNextTestSection', this.onlineTestSectionId, response.url);
          } else {
            if (API && API.LMSGetValue('contentQuestionnaireUrl')) {
              await new Promise((resolve, reject) => {
                swal(
                  {
                    icon: "info",
                    content: $(`<span>
                    アンケートのご<ruby>記入<rp>(</rp><rt>きにゅう</rt><rp>)</rp></ruby>をご<ruby>協力<rp>(</rp><rt>きょうりょく</rt><rp>)</rp></ruby><ruby>頂<rp>(</rp><rt>いただ</rt><rp>)</rp></ruby>ければ<ruby>幸<rp>(</rp><rt>さいわ</rt><rp>)</rp></ruby>いでございます。
                        </span>`)[0],
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ['終了', 'アンケートを回答'],
                    timer: 60000
                  }
                ).then((response) => {
                  try {
                    if (response) {
                      let newWindow = window.open(
                        API.LMSGetValue('contentQuestionnaireUrl'),
                        "_blank",
                        "resizable=yes, scrollbars=yes, status=no, toolbar=no, menubar=no, location=no"
                      );
                    }
                    resolve();
                  } catch (e) {
                    resolve();
                  }
                });
                $('.swal-button.swal-button--confirm')[0].innerHTML = this.$t('fillOutQuestionaire');
                $('.swal-button.swal-button--cancel')[0].innerHTML = this.$t('end');
              });
            }
            if (response.url) window.location.href = response.url;
            this.$emit('backToMenuPage');
          }
        });
      } else {
        if (response.message === 'section-activity-created') {
          swal({
            content: $(`<span>${this.$t('testSectionTested')}</span>`)[0],
            icon: 'success',
            buttons: this.mode === 'test' && this.$parent.checkNextTestSection(this.onlineTestSectionId) ? ['次のテストセクションへ', '終了'] : ['', '終了'],
            closeOnClickOutside: false,
            closeOnEsc: false,
            timer: 5000
          }).then((action) => {
            if (action) this.$emit('backToMenuPage');
            else {
              if (this.mode === 'test' && this.$parent.checkNextTestSection(this.onlineTestSectionId)) {
                this.$emit('switchToNextTestSection', this.onlineTestSectionId);
              } else {
                this.$emit('backToMenuPage');
              }
            }
          });
          $('.swal-button.swal-button--confirm')[0].innerHTML = this.$t('end');
          if (this.mode === 'test' && this.$parent.checkNextTestSection(this.onlineTestSectionId)) $('.swal-button.swal-button--cancel')[0].innerHTML = this.$t('nextTestSection');
        } else {
          await new Promise((resolve, reject) => {
            swal({
              content: $('<span>エラーが<ruby>発生<rp>(</rp><rt>はっせい</rt><rp>)</rp></ruby>しました。<ruby>下<rp>(</rp><rt>した</rt><rp>)</rp></ruby>の「<ruby>提出<rp>(</rp><rt>ていしゅつ</rt><rp>)</rp></ruby>」ボタンをクリックし、<ruby>再<rp>(</rp><rt>さい</rt><rp>)</rp></ruby><ruby>提出<rp>(</rp><rt>ていしゅつ</rt><rp>)</rp></ruby>してください。</span>')[0],
              buttons: this.mode === 'test' && this.$parent.checkNextTestSection(this.onlineTestSectionId) ? ['次のテストセクションへ', '提出'] : ['終了', '提出'],
              closeOnClickOutside: false,
              closeOnEsc: false,
            }).then(async response => {
              if (response) await this.commit();
              else {
                if (this.mode === 'test' && this.$parent.checkNextTestSection(this.onlineTestSectionId)) {
                  this.$emit('switchToNextTestSection', this.onlineTestSectionId);
                } else {
                  this.$emit('backToMenuPage');
                }
              }
              resolve();
            });
            if (this.mode === 'test' && this.$parent.checkNextTestSection(this.onlineTestSectionId)) $('.swal-button.swal-button--cancel')[0].innerHTML = this.$t('nextTestSection');
            else $('.swal-button.swal-button--cancel')[0].innerHTML = this.$t('end');
            $('.swal-button.swal-button--confirm')[0].innerHTML = this.$t('submit');
          });
        }
      }

      if (this.onlineTestSection.time_calculation_method === 'share') this.$parent.sharedTestTime = this.$parent.getRemainingTime();

      console.log('success=', response.success);
    },
    getResult() {
      let result = [];
      this.questions.forEach((question, questionIndex,) => {
        let temp = {};
        temp.question_id = question.id;
        switch (question.question_type_id) {
          case 1:
            temp.answer = [];
            question.question_choices.forEach((choice, choiceIndex) => {
              temp.answer.push({ 'id': choice.id, 'is_checked': this.answers[questionIndex] == choiceIndex + 1 });
            });
            break;
          case 4:
            temp.answer = this.answers[questionIndex];
            break;
        }
        result.push(temp);
      });
      return result;
    },
    getAudioUrl(audio) {
      if (!API) {
        return `${window.axios.defaults.baseURL}${audio.path}`;
      } else {
        return audio.path;
      }
    },
    audioEndedHandler() {
      if (this.playingQuestionIndex < this.audioUrlList.length) {
        if (this.playingAudioIndex == this.audioUrlList[this.playingQuestionIndex].length - 1) {
          if (this.playingQuestionIndex == (this.audioUrlList.length - 1)) return;
          this.playingQuestionIndex += 1;
          this.playingAudioIndex = 0;
        } else this.playingAudioIndex += 1;
        $(document).ready(() => {
          this.audioPlay();
          if (this.testPaused) this.restartTimer();
        });
      }
    },
    audioPauseHandler(e) {
      if (e.target.ended !== true) {
        this.audioPlay();
      }
    },
    async audioPlay() {
      this.$refs['overall-audio'].load();
      if (this.isIOS) {
        try {
          this.$refs['overall-audio'].play();
          await helpers.sleep(3000);
          if (this.$refs['overall-audio'].played.length === 0) throw 'Audio play error';
          this.restartTimer(
            this.restoreFromSaveData
              ? this.saveData.remainingTime
              : (this.onlineTestSection.time_calculation_method === "share"
                ? this.$parent.sharedTestTime
                : this.onlineTestSection.time
              )
          );
        } catch (e) {
          console.log(e);
          this.audioPlayErrorHandler();
        }
      } else {
        try {
          await this.$refs['overall-audio'].play();
          this.restartTimer(
            this.restoreFromSaveData
              ? this.saveData.remainingTime
              : (this.onlineTestSection.time_calculation_method === "share"
                ? this.$parent.sharedTestTime
                : this.onlineTestSection.time
              )
          );
        } catch (e) {
          console.log(e);
          this.audioPlayErrorHandler();
        }
      }
    },
    audioPlayErrorHandler() {
      this.pauseTimer();
      if (!swal.getState().isOpen)
        swal({
          icon: "error",
          content: $(`<span>${this.$t('badInternetConnectionUnableToGetAudios')}</span><br><br><span class="orange-text">（${this.$t('internetConnectedNormallyToGetAudios')}）</span>`)[0],
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          className: "audio-play-error"
        });
      this.audioPlay();
    },
    backToMenuPage() {
      this.$emit('backToMenuPage');
    },
    switchTimer() {
      this.testPaused ? this.restartTimer() : this.pauseTimer();
      // $('.tooltipped.switch-timer').tooltip('close');
      // setTimeout(() => {
      //   if ($('.fixed-action-btn')[0] && $('.fixed-action-btn')[0].M_FloatingActionButton.isOpen)
      //     $('.tooltipped.switch-timer').tooltip('open');
      // }, 500);
    },
    pauseTimer() {
      this.$emit('pauseTimer');
      this.testPaused = true;
    },
    restartTimer(limitTime) {
      if (swal.getState().isOpen) swal.close();
      this.$emit('restartTimer', limitTime);
      this.testPaused = false;
    },
    timeOver() {
      let second = 5;
      let timer;

      swal(
        {
          title: "時間になったため、テストが終了しました。",
          content: $(`<span>${this.$t('automaticallyScoreAfterNSeconds', { n: second })}</span>`)[0],
          icon: "warning",
          button: 'すぐ採点',
          closeOnClickOutside: false,
          closeOnEsc: false,
          timer: second * 1000,
        }
      ).then(() => {
        clearInterval(timer);
        this.commit();
      });

      $('.swal-title').html(this.$t('timeUpToEndTest'));
      $('.swal-button').html(this.$t('scoreImmediately'));

      timer = setInterval(() => {
        if (second < 1) {
          clearInterval(timer);
        } else {
          second -= 1;
          $('.swal-content').html(`<span>${this.$t('automaticallyScoreAfterNSeconds', { n: second })}</span>`);
        }
      }, 1000);
    },
    setSaveData() {
      if (API.LMSGetValue('isTeacher')) return;
      try {
        localStorage.setItem(this.saveDataName, JSON.stringify(this.getSaveData()));
      } catch (e) {
        console.log(e);
        if (!localStorage.getItem('unsaveData')) {
          swal('ローカルストレージの利用可能容量を超えたため、「テストデータの一時保存と回復機能」がブロックされました。<br>「テストデータの一時保存と回復機能」をご利用される方、ブラウザーキャッシュを一度削除してから、ご利用ください。', {
            icon: "warning"
          });
          $('.swal-text').html(`<span>${this.$t('localStorageExceeded')}<br>${this.$t('deleteBrowswerCache')}</span>`);
          localStorage.setItem('unsaveData', true);
        }
      }
    },
    getSaveData() {
      let data = {
        isFetchingQuestions: this.isFetchingData,
        testStarted: this.testStarted,
        isResultCommitted: this.isResultCommitted,
        sectionStartTimer: this.sectionStartTimer,
        sectionStartStandbyTime: helpers.getSectionStartRemainingTime() ? helpers.getSectionStartRemainingTime() : this.sectionStartStandbyTime,
        privilegeType: this.privilegeType,
        coursePrivilegeTime: API.LMSGetValue('coursePrivilegeTime'),
        mode: this.mode,
        testLanguageCode: this.testLanguageCode,
        displayRuby: this.displayRuby,
        choiceArrangement: this.choiceArrangement,
        onlineTestSectionId: this.onlineTestSectionId,
        questionTagCounts: this.questionTagCounts,
        questionTags: this.questionTags,
        answers: this.answers,
        remainingTime: this.testStarted && this.$parent.getRemainingTime() ? this.$parent.getRemainingTime() : (this.onlineTestSection.time_calculation_method === "share" ? this.$parent.sharedTestTime : this.onlineTestSection.time),
        inQuestionNumber: this.inQuestionNumber,
        playingQuestionIndex: this.playingQuestionIndex,
        playingAudioIndex: this.playingAudioIndex,
        playingAudioCurrentTime: this.$refs['overall-audio']
          ? this.$refs['overall-audio'].currentTime
          : $(`#audio-${this.playingQuestionIndex}-${this.playingAudioIndex}`)[0] && $(`#audio-${this.playingQuestionIndex}-${this.playingAudioIndex}`)[0].currentTime
            ? $(`#audio-${this.playingQuestionIndex}-${this.playingAudioIndex}`)[0].currentTime
            : 0,
        sharedTestTime: this.$parent.sharedTestTime,
        openedTestDirections: this.openedTestDirections,
      };
      return data;
    },
    getTemporarilySaveData() {
      let data = this.getSaveData();
      return data;
    },
    async openDirection() {
      if (!(this.testStarted && this.testDirection)) return;
      let opened = $.inArray(this.testDirection.id, this.openedTestDirections) > -1;
      let self = this;
      if (!opened && $('.direction-modal').length > 0) {
        while (!opened) {
          try {
            $('.direction-modal').modal('open');
            self.openedTestDirections.push(self.testDirection.id);
            opened = $.inArray(self.testDirection.id, self.openedTestDirections) > -1;
          } catch (e) {
            console.log(e);
            await helpers.sleep(500);
          }
        }
      }
    },
    getQuestionBreakdown() {
      let text = '<br />';
      let questionCounts = {};
      this.questions.forEach(question => {
        let testSectionName = API.translation_joined(question.test_section, this.questions[0]?.language.alias, this.$i18n.locale);
        if (!questionCounts.hasOwnProperty(testSectionName)) questionCounts[testSectionName] = {};
        let questionCountsOfTagNames = questionCounts[testSectionName];
        let tagIds = question.question_tags.map(tag => tag.id);
        let tagNames = this.questionTags ? this.questionTags.filter(tag => tagIds.indexOf(tag.id) > -1).map(tag => API.translation_joined(tag, this.questions[0]?.language.alias, this.$i18n.locale)).join(' | ') : '-';
        if (!questionCountsOfTagNames.hasOwnProperty(tagNames)) questionCountsOfTagNames[tagNames] = 0;
        questionCountsOfTagNames[tagNames] += 1;
      });
      for (let testSectionName in questionCounts) {
        text += "<table><tbody>";
        let questionCountsOfTagNames = questionCounts[testSectionName];
        let index = 0;
        for (let tagNames in questionCountsOfTagNames) {
          text += `<tr class="question-breakdown-item">${index ? "" : `<td class="test-section-name" rowspan=${Object.keys(questionCountsOfTagNames).length}>${testSectionName}</td>`}<td class="question-tag-name">${tagNames}</td><td class="question-tag-count">${this.$i18n.tc('nQuestions', questionCountsOfTagNames[tagNames], { n: questionCountsOfTagNames[tagNames] })}</td></tr>`;
          index += 1;
        }
        text += "</tbody></table>";
      }
      return text;
    }
  },
  computed: {
    currQuestion() {
      return this.questions[this.inQuestionNumber - 1];
    },
    isLoadingAudios() {
      if (this.isIOS) return false;
      return this.loadedAudioNum < this.totalAudioNum;
    },
    testDirection() {
      let testDirection = this.testDirections.find(testDirection => {
        return testDirection.id === this.currQuestion.test_direction_id;
      });

      if (!testDirection) return null;

      if (testDirection.text.includes("{{question_breakdown_header_text}}")) testDirection.text = testDirection.text.replaceAll(/{{question_breakdown_header_text}}/g, this.$t("questionBreakdownCategorizedByCandoTag"));
      if (testDirection.text.includes("{{question_breakdown_by_tag}}")) {
        let text = this.getQuestionBreakdown();
        testDirection.text = testDirection.text.replaceAll(/{{question_breakdown_by_tag}}/g, text);
      }
      return testDirection;
    }
  },
  beforeDestroy() {
    if (this.$refs['overall-audio']) {
      this.$refs['overall-audio'].removeEventListener('ended', this.audioEndedHandler);
      this.$refs['overall-audio'].removeEventListener('pause', this.audioPauseHandler);
    }
    clearInterval(this.saveDataInterval);
    localStorage.removeItem('unsaveData');
  }
}
</script>

<style lang="scss" scoped>
.overall-audio-container {
  display: none;
}

.fixed-action-btn {
  bottom: 10vh;
  padding: unset;

  @media only screen and (max-width: 600px) {
    opacity: 80%;

    &:hover {
      opacity: 100%;
    }
  }
}
</style>