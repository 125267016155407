<template>
  <div v-if="question" class="question-text" style="display:flex">
    <p><b v-html="displayNumber"></b></p>
    <div>
      <slot></slot> <!-- audio for listening -->
      <p v-html="API.translation(question, question.language.alias, testLanguageCode)"></p>
      <p v-for="(questionChoice, index) in question.question_choices" :key="index">
        <label class="black-text cursor-pointer">
          <input :name="`question-choice-${questionNumber}`" class="position-fixed" type="radio" :value="index+1" v-model="answer" :set="answerInSet = answerState">
          <span>({{ String.fromCharCode(index + 65) }})&nbsp;&nbsp;</span>
          <span v-html="API.translation(questionChoice, question.language.alias, testLanguageCode)"></span>
        </label>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    displayNumber: String,
    question: Object,
    questionNumber: Number,
    testLanguageCode: String,
  },
  data() {
    return {
      API,
      answer: "",
    }
  },
  watch: {
    answer(value) {
      this.$store.dispatch("answersState/setAnswer", {key: this.questionNumber - 1, value: value ?? ''})
    }
  },
  computed: {
    answerState() {
      let answerState = this.$store.getters['answersState/getAnswers'][this.questionNumber - 1];
      this.answer = answerState;
      return answerState;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.question-text {
  margin-bottom: 5vh;

  div {
    flex-grow: 1;
  }
}
</style>