import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from './en';
import id from './id';
import ja from './ja';
import ne from './ne';
import vi from './vi';
import zh from './zh';

Vue.use(VueI18n)

// const locale = 'ja' // default locale

export default new VueI18n({
  locale: 'ja',
  messages: {
    en,
    id,
    ja,
    ne,
    vi,
    zh
  }
});