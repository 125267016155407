<template>
  <div class="footer">
    <a
      class="waves-effect waves-light btn-large"
      :disabled="!prevQuestionNumber"
      @click="redirect(prevQuestionNumber)"
    >
      <i class="material-icons left margin-x-0">chevron_left</i>
      <!-- 前へ　まえへ -->
      <span class="words" v-html="$t('previous')"></span>
    </a>
    <a
      class="waves-effect waves-light btn-large modal-trigger"
      href="#question-list">
      <i class="material-icons left margin-x-0">list</i>
      <!-- 問題一覧　もんだいいちらん -->
      <span class="words" v-html="$t('questionList')"></span>
    </a>
    <div id="question-list" class="modal bottom-sheet question-list">
      <div class="modal-content row">
        <div class="question-list-close">
          <!-- 閉じる　とじる -->
          <a href="#!" class="modal-close grey-text" v-html="$t('close')"></a>
        </div>
        <h4 class="question-list-title" v-html="$t('questionList')"></h4>
        <div class="d-flex row question-list-content single-col col s12 m12">
          <div class="col s12">
            <div class="d-flex row question-list-header">
              <div class="col s4 question-list-grid" v-html="$t('questionNumber')"></div>
              <div class="col s4 question-list-grid status" v-html="$t('status')"></div>
              <div class="col s4 question-list-grid" v-html="$t('answer')"></div>
            </div>
            <div class="question-list-body">
              <div
                v-for="(question, index) in questions"
                :key="index"
                class="d-flex row flex-wrap-nowrap"
                @click="redirect(index+1)"
              >
                <div
                  class="col s4 question-list-grid"
                  :class="$parent.$refs[`answerSheet`].answers[index] ? '' : 'no-answer'"
                >
                  {{ question ? question.display_number_1 : '-' }}
                </div>
                <div
                  class="col s4 question-list-grid status"
                  :class="$parent.$refs[`answerSheet`].answers[index] ? '' : 'no-answer'"
                  v-html="$parent.$refs[`answerSheet`].answers[index]
                            ? $t('answered')
                            : $t('unanswered')"
                >
                </div>
                <div
                  class="col s4 question-list-grid"
                  :class="$parent.$refs[`answerSheet`].answers[index] ? '' : 'no-answer'"
                >
                  {{ $parent.$refs[`answerSheet`].answers[index] ? String.fromCharCode(64 + $parent.$refs[`answerSheet`].answers[index]) : '-' }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex row question-list-content double-cols col s12 m12">
          <div class="col s6">
            <div class="d-flex row question-list-header">
              <div class="col s4 question-list-grid" v-html="$t('questionNumber')"></div>
              <div class="col s4 question-list-grid status" v-html="$t('status')"></div>
              <div class="col s4 question-list-grid" v-html="$t('answer')"></div>
            </div>
            <div class="question-list-body">
              <div
                v-for="(question, index) in questions"
                :key="index"
                v-if="index % 2 == 0"
                class="d-flex row flex-wrap-nowrap"
                @click="redirect(index+1)"
              >
                <div
                  class="col s4 question-list-grid"
                  :class="$parent.$refs[`answerSheet`].answers[index] ? '' : 'no-answer'"
                >
                  {{ question ? question.display_number_1 : '-' }}
                </div>
                <div
                  class="col s4 question-list-grid status"
                  :class="$parent.$refs[`answerSheet`].answers[index] ? '' : 'no-answer'"
                  v-html="$parent.$refs[`answerSheet`].answers[index]
                            ? $t('answered')
                            : $t('unanswered')"
                >
                </div>
                <div
                  class="col s4 question-list-grid"
                  :class="$parent.$refs[`answerSheet`].answers[index] ? '' : 'no-answer'"
                >
                  {{ $parent.$refs[`answerSheet`].answers[index] ? String.fromCharCode(64 + $parent.$refs[`answerSheet`].answers[index]) : '-' }}
                </div>
              </div>
            </div>
          </div>
          <div class="col s6">
            <div class="d-flex row question-list-header">
              <div class="col s4 question-list-grid" v-html="$t('questionNumber')"></div>
              <div class="col s4 question-list-grid status" v-html="$t('status')"></div>
              <div class="col s4 question-list-grid" v-html="$t('answer')"></div>
            </div>
            <div class="question-list-body">
              <div
                v-for="(question, index) in questions"
                :key="index"
                v-if="index % 2 != 0"
                class="d-flex row flex-wrap-nowrap"
                @click="redirect(index+1)"
              >
                <div
                  class="col s4 question-list-grid"
                  :class="$parent.$refs[`answerSheet`].answers[index] ? '' : 'no-answer'"
                >
                  {{ question ? question.display_number_1 : '-' }}
                </div>
                <div
                  class="col s4 question-list-grid status"
                  :class="$parent.$refs[`answerSheet`].answers[index] ? '' : 'no-answer'"
                  v-html="$parent.$refs[`answerSheet`].answers[index]
                            ? $t('answered')
                            : $t('unanswered')"
                >
                </div>
                <div
                  class="col s4 question-list-grid"
                  :class="$parent.$refs[`answerSheet`].answers[index] ? '' : 'no-answer'"
                >
                  {{ $parent.$refs[`answerSheet`].answers[index] ? String.fromCharCode(64 + $parent.$refs[`answerSheet`].answers[index]) : '-' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a
     class="waves-effect waves-light btn-large"
     :disabled="!nextQuestionNumber"
     @click="redirect(nextQuestionNumber)"
    >
      <!-- 次へ -->
      <span class="words" v-html="$t('next')"></span>
      <i class="material-icons right margin-x-0">chevron_right</i>
    </a>
    <a
     class="waves-effect waves-light btn-large"
     @click="submit()"
    >
      <i class="material-icons left margin-x-0">send</i>
      <!-- 結果を提出　けっかをていしゅつ -->
      <span class="words" v-html="$t('allFinished')"></span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    questions: Array,
    prevQuestionNumber: Number,
    nextQuestionNumber: Number,
  },
  data() {
    return {
      numberOfQuestions: this.questions.length,
    }
  },
  created() {
    $(document).ready(()=>{
      M.AutoInit();
      this.setListeners();
    });
  },
  methods: {
    redirect(questionNumber) {
      this.$parent.inQuestionNumber = questionNumber;
      $('#question-list').modal('close');
    },
    submit() {
      swal('テスト結果を提出してよろしいですか？', {
        buttons: [this.$t('no'), this.$t('yes')],
        icon: "warning"
      }).then(response => {
        if (response) this.$emit('submit');
      });
      $('.swal-text').html(`<div>${this.$t('wantToSubmitResults')}</div><div class="d-flex justify-content-center"><span>${this.$t('unansweredQuestionNumber', {n:this.unansweredQuestionAmount})}</span></div>`);
    },
    setListeners() {
      let test = this.$parent;
      $('#question-list').modal({
        onOpenStart: function() {
          test.isQuestionListOpen = true;
          test.isAnswerSheetOpen = false;
        },
        onCloseEnd: function() {
          test.isQuestionListOpen = false;
        },
      });
    }
  },
  computed: {
    unansweredQuestionAmount() {
      return this.$parent.$refs.answerSheet.answers.filter(answer=>{
        return !answer;
      }).length;
    }
  }
}
</script>

<style lang="scss" scoped>
// .footer {
//   position: fixed;
//   bottom: 0px;
//   display: flex;
//   width: 100%;
//   max-height: 10%;
//   justify-content: center;
//   left: 0px;
  
//   .btn-large {
//     width: 15rem;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     white-space: nowrap;
//     font-size:1.5vw;
//   }

//   .words {
//     @media screen and (max-width: 600px){
//       display: none;
//     }
//   }
// }

// .question-list {
  // max-height: 100% !important;
  // overflow: unset;
  // .question-list-close {
  //   position: fixed;
  //   right: 0;
  //   margin-right: 2rem;
  // }
  // .question-list-title {
  //   width: 100%;
  //   margin: {
  //     top: 2vh;
  //     bottom: 2vh;
  //   }
  // }

  // .question-list-content {
  //   overflow: auto;
  //   max-height: 50vh;
  //   @media screen and (max-width: 600px) {
  //     &.double-cols {
  //       display: none;
  //     }
  //   }

  //   @media screen and (min-width: 601px) {
  //     &.single-col {
  //       display: none;
  //     }
  //   }
  // }

  // .row {
  //   margin: unset;
  //   cursor: pointer;
  // }
  // .question-list-header {
  //   border-radius: 5%;
  //   flex-wrap: nowrap;
  //   .question-list-grid {
  //     color: black;
  //     font-weight: bold;
  //     padding: {
  //       top: 1vh;
  //       bottom: 1vh;
  //     }
  //     margin: 2px;
  //     min-width: 50px;

  //     &.status {
  //       min-width: 6rem;
  //     }
  //   }
  // }
  // .question-list-body {
  //   .question-list-grid {
  //     color: white;
  //     border: 0.5px solid gray;
  //     padding: {
  //       top: 1vh;
  //       bottom: 1vh;
  //     }
  //     margin: 2px;
  //     background-color: #26a69a;
  //     min-width: 50px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     line-height: 1;

  //     &.status {
  //       min-width: 6rem;
  //       display: flex;
  //       align-items: baseline;
  //       white-space: nowrap;
  //     }

  //     &.no-answer {
  //       background-color: #f39194;
  //     }
  //   }

  // }
// }
</style>