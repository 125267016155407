<template>
  <div v-if="isInitialized" class="dashboard">
    <nav class="fixed-nav d-flex row justify-content-center margin-0 red lighten-1">
      <div class="d-flex col s3 justify-center align-items-center">
        <Timer v-if="page == 'test'" ref="timer" @timeOver="timeOver" />
      </div>
      <div class="title col s4 height-percentage-100 d-flex justify-content-center align-items-center">
        <a class="center">{{ onlineTest.name }}</a>
      </div>
      <div class="col s3">
        <a v-if="page === 'menu'" @click="close">
          <span class="close-button">
            <i class="material-icons">exit_to_app</i>
            <span v-html="$t('end')" class="line-height-1"></span>
          </span>
        </a>
      </div>
    </nav>
    <div class="container">
      <div v-if="!isFetchingData" class="fetched-data">
        <Menu v-if="page == 'menu'" :onlineTestSections="onlineTestSections" :privilegeType="privilegeType" :mode="mode"
          :availableTestSectionIds="availableTestSectionIds" @changePage="changePage">
          <div class="base-info col s12 margin-y-5">
            <div class="d-flex align-items-center justify-content-center margin-y-2 flex-wrap-wrap">
              <span v-html="`${$t('licenseTypeInUse')}：　`"></span>
              <span class="green-text font-weight-bold"
                v-html="privilegeType === 'term' ? $t('termType') : $t('timesType')">
              </span>
              <button v-if="window.API.LMSGetValue('timesEndWhen') === 'completed'"
                class="select-times-btn btn margin-left-5 font-10"
                @click="window.API.LMSSetValue('coursePrivilegeTime', undefined); init()" v-html="$t('selectTimes')">
              </button>
            </div>
            <div class="d-flex align-items-center justify-content-center margin-y-2 flex-wrap-wrap">
              <span v-html="`${$t('modeInUse')}：　`"></span>
              <span class="orange-text font-weight-bold"
                v-html="mode === 'practice' ? $t('practiceMode') : $t('testMode')">
              </span>
              <button
                v-if="!(window.API.LMSGetValue('mode') || (window.API.getProgressText() && window.API.getProgressText().mode))"
                class="select-btn btn margin-left-5 font-10" @click="showModeSelector" v-html="$t('selectMode')"></button>
            </div>
            <div v-if="selectableTestLanguages.length > 0"
              class="d-flex align-items-center justify-content-center margin-y-2 flex-wrap-wrap">
              <span v-html="`${$t('testLanguageSelected')}：　`"></span>
              <span class="indigo-text font-weight-bold" v-html="testLanguage ? testLanguage.name : ''">
              </span>
              <button
                v-if="!(window.API.LMSGetValue('testLanguageCode') || (window.API.getProgressText() && window.API.getProgressText().testLanguageCode))"
                class="select-btn btn margin-left-5 font-10 indigo" @click="showTestLanguageSelector"
                v-html="$t('selectTestLanguage')"></button>
            </div>
            <div v-if="onlineTest.options && onlineTest.options.display_ruby_selectable"
              class="d-flex align-items-center justify-content-center margin-y-2 flex-wrap-wrap">
              <span v-html="`${$t('displayRuby')}：　`"></span>
              <span class="blue-text font-weight-bold" v-html="displayRuby === true
                ? $t('display')
                : $t('hide')">
              </span>
              <button v-if="!window.API || !(typeof window.API.LMSGetValue('displayRuby') === 'boolean'
                || (window.API.getProgressText()
                  && typeof window.API.getProgressText().display_ruby === 'boolean'))"
                class="select-btn btn margin-left-5 font-10 blue" @click="showDisplayRubySelector"
                v-html="$t('selectDisplayRuby')"></button>
            </div>
            <div v-if="onlineTest.options && onlineTest.options.choice_arrangement_selectable"
              class="d-flex align-items-center justify-content-center margin-y-2 flex-wrap-wrap">
              <span v-html="`${$t('choiceArrangement')}：　`"></span>
              <span class="pink-text font-weight-bold"
                v-html="choiceArrangement === 'shuffle' ? $t('shuffle') : $t('fix')"></span>
              <button v-if="!window.API
                || !(window.API.LMSGetValue('choiceArrangement')
                  || (window.API.getProgressText()
                    && typeof window.API.getProgressText().choice_arrangement))"
                class="select-btn btn margin-left-5 font-10 pink" @click="showChoiceArrangementSelector"
                v-html="$t('changeChoiceArrangement')"></button>
            </div>
          </div>
        </Menu>
        <component :is="testComponent" v-for="(onlineTestSectionId, index) in onlineTestSectionIds"
          v-if="page == 'test' && onlineTestSectionId == testingSectionId" :ref="`test-${onlineTestSectionId}`"
          :key="index" :onlineTestId="onlineTestId" :onlineTestSection="getOnlineTestSection(onlineTestSectionId)"
          :testDirections="onlineTest.test_level.test_directions" :testParts="onlineTest.test_level.test_parts"
          :testCode="onlineTest.test.code" :testLevelCode="onlineTest.test_level.code"
          :onlineTestSectionId="onlineTestSectionId" :testLanguageCode="testLanguageCode" :privilegeType="privilegeType"
          :mode="mode" :sectionStartTimer="sectionStartTimer" :sectionStartStandbyTime="sectionStartStandbyTime"
          :displayRuby="displayRuby" :groupQuestionDisplayMethod="groupQuestionDisplayMethod"
          :choiceArrangement="choiceArrangement" :restoreFromSaveData="restoreFromSaveData" :saveDataName="saveDataName"
          :saveData="saveData" :commitImmediately="commitImmediately" @backToMenuPage="backToMenuPage" @close="close"
          @switchToNextTestSection="switchToNextTestSection" @pauseTimer="pauseTimer" @restartTimer="restartTimer"
          :isIOS="isIOS" />
      </div>
      <div v-else class="fetching-data">
        <PreloaderCircle />
      </div>
    </div>
  </div>
</template>

<script>
import Test from './default/Test';
import TestToeic from './toeic/Test';
import Menu from './Menu';
import Timer from './base/Timer';
import PreloaderCircle from './base/PreloaderCircle';
import helpers from '../js/utils/helpers';
import localAPI from '../js/utils/API';

export default {
  name: 'Dashboard',
  components: {
    Test,
    TestToeic,
    Menu,
    Timer,
    PreloaderCircle
  },
  data() {
    return {
      window,
      languages: [],
      onlineTest: {},
      mode: '',
      testLanguageCode: '',
      sectionStartTimer: true,
      sharedTestTime: null,
      sectionStartStandbyTime: 180,
      displayRuby: '',
      groupQuestionDisplayMethod: '',
      choiceArrangement: '',
      privilegeType: 'term',
      page: 'menu',
      testingSectionId: '',
      // usingCoursePrivilegeTime: null,
      isFetchingData: true,
      isInitialized: false,
      restoreFromSaveData: false,
      saveDataName: '',
      saveData: null,
      commitImmediately: false,
    }
  },
  created() {
    this.fetchAllLanguages();
    if (!API) {
      window.API = localAPI;
      console.log('Local API loaded.');
    }
    this.init();
  },
  methods: {
    async init() {
      await API.init();
      this.isInitialized = false;
      this.restoreFromSaveData = false;
      this.commitImmediately = false;
      // Return to the section-selecting page for the time（回） being taken.
      let coursePrivilegeTime = API.LMSGetValue('coursePrivilegeTime');

      this.$i18n.locale = API.getOperationLanguage();

      await API.fetchPackagePrivilege();
      API.initializeCache();

      this.saveDataName = `online_test_data_${API.LMSGetValue('studentId')}_${API.LMSGetValue('contentId')}`;
      this.saveData = JSON.parse(localStorage.getItem(this.saveDataName));
      
      if (this.saveData) {
        await new Promise(resolve => {
          swal({
            content: $(`<div>${this.$t('unsubmittedDataDetected')}</div>`)[0],
            buttons: {
              cancel: {
                text: "データを削除する",
                value: false,
                visible: true,
                className: "red white-text",
                closeModal: true,
              },
              confirm: {
                text: "続ける",
                value: true,
                visible: true,
                className: "",
                closeModal: true
              }
            },
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then(async response => {
            if (!response) {
              localStorage.removeItem(this.saveDataName);
              this.saveData = null;
            } else {
              this.restoreFromSaveData = true;
            }
            resolve();
          });
          $('.swal-button--cancel').html(this.$t('deleteData'));
          $('.swal-button--confirm').html(this.$t('continueLastTest'));
        });
      }

      this.privilegeType = 'term';
      this.testingSectionId = '';
      this.untestedSectionCodes = [];

      if (this.restoreFromSaveData) API.LMSSetValue('contentActivity', this.saveData.coursePrivilegeTime.content_activity);
      if (this.restoreFromSaveData) coursePrivilegeTime = this.saveData.coursePrivilegeTime;
      let coursePrivilegeTimeId = coursePrivilegeTime ? coursePrivilegeTime.id : undefined;

      this.onlineTestId = API.LMSGetValue('onlineTestId');
      await this.fetchOnlineTest(this.onlineTestId);
      this.privilegeType = this.restoreFromSaveData ? this.saveData.privilegeType : API.LMSGetValue('privilegeType');
      if (!this.mode) this.mode = this.restoreFromSaveData ? this.saveData.mode : API.LMSGetValue('mode');
      if (this.restoreFromSaveData) this.testLanguageCode = this.saveData.testLanguageCode;
      this.sectionStartTimer = this.restoreFromSaveData ? this.saveData.sectionStartTimer : API.LMSGetValue('sectionStartTimer');
      this.sectionStartStandbyTime = this.restoreFromSaveData ? this.saveData.sectionStartStandbyTime : API.LMSGetValue('sectionStartStandbyTime');
      if (typeof this.displayRuby !== 'boolean') this.displayRuby = this.restoreFromSaveData ? this.saveData.displayRuby : API.LMSGetValue('displayRuby');
      if (!this.choiceArrangement) this.choiceArrangement = this.restoreFromSaveData ? this.saveData.choiceArrangement : API.LMSGetValue('choiceArrangement');
      let coursePrivilegeTimes = API.LMSGetValue('coursePrivilegeTimes');
      if (coursePrivilegeTimes) coursePrivilegeTime = coursePrivilegeTimes.find((coursePrivilegeTime) => {
        return coursePrivilegeTime.id == coursePrivilegeTimeId
      });

      if (coursePrivilegeTime) {
        API.setCoursePrivilegeTime(coursePrivilegeTime);
        if (!this.mode) this.mode = API.LMSGetValue('mode');
        if (!this.testLanguageCode && this.selectableTestLanguages.length > 0) this.testLanguageCode = API.LMSGetValue('testLanguageCode');
        if (typeof this.displayRuby !== 'boolean') this.displayRuby = API.LMSGetValue('displayRuby');
        this.groupQuestionDisplayMethod = API.LMSGetValue('groupQuestionDisplayMethod');
        if (!this.choiceArrangement) this.choiceArrangement = API.LMSGetValue('choiceArrangement');
      }

      if (coursePrivilegeTimes && !coursePrivilegeTime) {
        await this.showTimesSelector();
      }

      if (this.restoreFromSaveData) {
        if (API && !(await API.checkPermission() && (this.privilegeType !== 'times' || API.checkExpiredTime(API.LMSGetValue('packagePrivilegeTime')))))
          this.commitImmediately = true;
        this.sharedTestTime = this.saveData.sharedTestTime;
        this.changePage('test', this.saveData.onlineTestSectionId);
      }

      if (this.selectableTestLanguages.length > 0 && !this.testLanguageCode) {
        await this.showTestLanguageSelector();
      }

      if (!this.mode) {
        await this.showModeSelector();
      }

      if (this.onlineTest.options) {
        if (this.onlineTest.options.display_ruby_selectable && typeof this.displayRuby !== 'boolean') {
          await this.showDisplayRubySelector();
        }

        this.groupQuestionDisplayMethod = this.onlineTest.options.group_question_display_method;

        if (this.onlineTest.options.choice_arrangement_selectable) {
          if (!this.choiceArrangement) await this.showChoiceArrangementSelector();
        } else this.choiceArrangement = 'fix';
      } else this.choiceArrangement = 'fix';

      this.isInitialized = true;
    },
    getOnlineTestSection(onlineTestSectionId) {
      return this.onlineTest.online_test_sections.find((onlineTestSection) => {
        return onlineTestSection.id == onlineTestSectionId;
      });
    },
    async showTimesSelector() {
      this.mode = '';
      this.testLanguageCode = '';
      let packagePrivilegeTimes = API.LMSGetValue('packagePrivilegeTimes');
      let coursePrivilegeTimes = API.LMSGetValue('coursePrivilegeTimes');
      let courseRemainingTimes = API.getCourseRemainingTimes(API.LMSGetValue('courseId'));
      let buttons = {};
      coursePrivilegeTimes.forEach(function (coursePrivilegeTime, index) {
        let packagePrivilegeTime = packagePrivilegeTimes.find(
          packagePrivilegeTime => packagePrivilegeTime.id == coursePrivilegeTime.package_privilege_time_id
        );
        let mode = API.getOptionFromPackagePrivilegeTime(packagePrivilegeTime, 'mode') ? API.getOptionFromPackagePrivilegeTime(packagePrivilegeTime, 'mode') : API.getOptionFromCoursePrivilegeTime(coursePrivilegeTime, 'mode');
        buttons[index] = {
          text: `${index + 1}回目
                    　｜　進捗状況：${coursePrivilegeTime.completed_at ? '完了' : '未完了'}
                    　｜　開始時間：${coursePrivilegeTime.created_at}${coursePrivilegeTime.completed_at ? `
                    　｜　完了時間：${coursePrivilegeTime.completed_at}` : ''}
                    　｜　モード：${!mode
              ? '未選択'
              : mode === 'practice' ? '練習' : (mode === 'test' ? '受験' : '未選択')}`,
          value: coursePrivilegeTime,
          className: `${API.checkExpiredTime(packagePrivilegeTime) && !coursePrivilegeTime.completed_at ? '' : 'disabled'}`,
          disabled: coursePrivilegeTime.completed_at ? true : false
        }
      }, this);

      if (!(this.privilegeType === 'times' && courseRemainingTimes < 1)
            && !(coursePrivilegeTimes && coursePrivilegeTimes.length > 0 && !coursePrivilegeTimes[coursePrivilegeTimes.length-1].content_activity))
        buttons['new'] = {
          text: '新しい回',
          className: 'new',
          value: '',
        }
      let self = this;
      await new Promise((resolve, reject) => {
        swal({
          content: $(`<span>${self.$t('pleaseSelectNumerOfTimes')}</span>`)[0],
          buttons,
          className: "select-mode",
          closeOnClickOutside: false,
          closeOnEsc: false,
          closeModal: false
        }).then(async response => {
          if (response) {
            API.setCoursePrivilegeTime(response);
            let packagePrivilegeTime = API.LMSGetValue('packagePrivilegeTime');
            self.mode = API.LMSGetValue('mode');
            self.testLanguageCode = API.getOptionFromCoursePrivilegeTime(response, 'test_language_code');
            self.displayRuby = API.getOptionFromPackagePrivilegeTime(packagePrivilegeTime, 'display_ruby') ? API.getOptionFromPackagePrivilegeTime(packagePrivilegeTime, 'display_ruby') : API.getOptionFromCoursePrivilegeTime(response, 'display_ruby');
            self.choiceArrangement = API.getOptionFromPackagePrivilegeTime(packagePrivilegeTime, 'choice_arrangement') ? API.getOptionFromPackagePrivilegeTime(packagePrivilegeTime, 'choice_arrangement') : API.getOptionFromCoursePrivilegeTime(response, 'choice_arrangement');
            resolve();
          } else {
            let params = {
              title: "新しい回を開始してよろしいですか？",
              content: courseRemainingTimes !== null ? $(`<div class="swal-text margin-0">${self.$t('currentAvailableTimes', {n: courseRemainingTimes})}</div>`)[0] : "",
              buttons: [self.$t('no'), self.$t('yes')],
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            };
            await new Promise((resolve2, reject) => {
              swal(
                params
              ).then(async newResponse => {
                if (newResponse) {
                  let coursePrivilegeTime = await API.recountTimes();
                  API.setCoursePrivilegeTime(coursePrivilegeTime);
                  if (!self.mode) self.mode = API.LMSGetValue('mode');
                  if (typeof self.displayRuby !== 'boolean') self.displayRuby = API.LMSGetValue('displayRuby');
                  if (!self.choiceArrangement) self.choiceArrangement = API.LMSGetValue('choiceArrangement');
                } else {
                  await self.showTimesSelector();
                }
                resolve2()
                resolve();
              });
              $('.swal-title').html(this.$t('wantToStartNewTime'));
              if (window.innerWidth > 500) $('.swal-modal').css({ "width": '500px' });
              window.onresize = () => {
                if (window.innerWidth > 500) $('.swal-modal').css({ "width": '500px' });
                else $('.swal-modal').css({ "width": '' });
              };
            });
          }
        });
        coursePrivilegeTimes.forEach(function (coursePrivilegeTime, index) {
          let mode = API.getOptionFromCoursePrivilegeTime(coursePrivilegeTime, 'mode')
            ? API.getOptionFromCoursePrivilegeTime(coursePrivilegeTime, 'mode')
            : API.LMSGetValue('mode');
          $(`.swal-button--${index}`).html(`${self.$t('timesSequence', { n: self.sequence(index + 1) })} \
                                              　｜　${self.$t('progress')}：${coursePrivilegeTime.completed_at ? self.$t('finished') : self.$t('unfinished')}\
                                              　｜　${self.$t('startTime')}：${coursePrivilegeTime.created_at}\
                                              ${coursePrivilegeTime.completed_at ? `　｜　${self.$t('finishedTime')}：${coursePrivilegeTime.completed_at}` : ''}
                                              　｜　${self.$t('mode')}：${!mode ? self.$t('unselected')
              : mode === 'practice' ? self.$t('practice') : mode === 'test' ? self.$t('test') : self.$t('unselected')}`);
        });
        $(".swal-button--new").html(self.$t('newTime'));
      });
    },
    async showModeSelector() {
      await new Promise((resolve, reject) => {
        swal({
          content: $(`<span>${this.$t("pleaseSelectMode")}</span>`)[0],
          buttons: {
            practice: {
              text: "れんしゅう　モード",
              value: "practice",
            },
            test: {
              text: "テスト　モード",
              value: "test",
            },
          },
          className: "select-mode",
          closeOnClickOutside: false,
          closeOnEsc: false,
          closeModal: false,
        }).then(response => {
          this.mode = response;
          resolve();
        }).catch(error => {
          console.log(error);
          reject();
        });
        $(".swal-button--practice").html(this.$t("practiceMode"));
        $(".swal-button--test").html(this.$t("testMode"));
      });
    },
    async showTestLanguageSelector() {
      let content = $(`<span>${this.$t("pleaseSelectTestLanguage")}</span>`)[0];
      let buttons = {};
      this.selectableTestLanguages.forEach(
        (language, index) => {
          buttons[index] = {
            text: language.name,
            value: language.alias,
          }
        }
      );
      this.testLanguageCode = await this.showSelector(content, buttons)
    },
    async showDisplayRubySelector() {
      let content = $(`<span>${this.$t("pleaseSelectDisplayRuby")}</span>`)[0];
      let buttons = {
        0: {
          text: "表示",
          value: true,
          html: this.$t("display")
        },
        1: {
          text: "非表示",
          value: false,
          html: this.$t("hide")
        },
      };
      this.displayRuby = await this.showSelector(content, buttons);
    },
    async showChoiceArrangementSelector() {
      let content = $(`<span>${this.$t("pleaseSelectChoiceArrangement")}</span>`)[0];
      let buttons = {
        0: {
          text: "固定",
          value: 'fix',
          html: this.$t("fix")
        },
        1: {
          text: "シャッフル",
          value: 'shuffle',
          html: this.$t("shuffle")
        },
      };
      this.choiceArrangement = await this.showSelector(content, buttons);
    },
    async showSelector(content, buttons) {
      return await new Promise((resolve, reject) => {
        swal({
          content,
          buttons,
          className: "select-mode",
          closeOnClickOutside: false,
          closeOnEsc: false,
          closeModal: false,
        }).then(response => {
          resolve(response);
        }).catch(error => {
          console.log(error);
          reject();
        });
        for (let i = 0; i < Object.keys(buttons).length; i++) {
          $(`.swal-button--${i}`).html(`${buttons[i].html ? buttons[i].html : buttons[i].text}`);
        }
      });
    },
    async fetchOnlineTest(onlineTestId) {
      this.isFetchingData = true;

      this.onlineTest = await API.fetchOnlineTest(onlineTestId);
      this.isFetchingData = false;
    },
    async fetchAllLanguages() {
      this.languages = await API.fetchAllLanguages();
    },
    changePage(page, onlineTestSectionId) {
      this.page = page;
      this.testingSectionId = onlineTestSectionId;
    },
    backToMenuPage() {
      $('.tooltipped').tooltip('close');
      API.setConfirmBeforeLeave(false);
      localStorage.removeItem(this.saveDataName);
      this.page = 'menu';
      this.resetTimer();
      this.init();
    },
    async switchToNextTestSection(onlineTestSectionId, redirectUrl, timer = 10000) {
      if (this.restoreFromSaveData && this.onlineTestSections[0].time_calculation_method === 'share') this.sharedTestTime = this.saveData.remainingTime;
      if (this.checkNextTestSection(onlineTestSectionId)) {
        let index = this.onlineTestSectionIds.findIndex((testSection) => {
          return testSection == onlineTestSectionId;
        });
        await swal(
          {
            content: $(`<span>${this.$t("moveToNextTestSection")}</span>`)[0],
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: false,
            timer
          }
        ).then(async () => {
          this.restoreFromSaveData = false;
          this.saveData = null;
          this.resetTimer();
          if (this.page === 'test') this.testingSectionId = this.onlineTestSectionIds[index + 1];
          else this.changePage('test', this.onlineTestSectionIds[index + 1]);
        });
      }
      else {
        let self = this;
        swal({
          content: $(`<div><div>${this.$t("testEnded")}</div><div>${this.$t("wellDone")}</div></div>`)[0],
          icon: 'success',
          buttons: {
            confirm: {
              text: "終了",
              value: true,
              visible: true,
              className: "",
              closeModal: true
            }
          },
          closeOnClickOutside: false,
          closeOnEsc: false,
          timer
        })
          .then(async () => {
            if (API && API.LMSGetValue('contentQuestionnaireUrl')) {
              await new Promise((resolve, reject) => {
                swal(
                  {
                    icon: "info",
                    content: $(`<span>${this.$t("pleasefillOutQuestionaire")}</span>`)[0],
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ['終了', 'アンケートを回答'],
                    timer: 60000
                  }
                ).then((response) => {
                  try {
                    if (response) {
                      let newWindow = window.open(
                        API.LMSGetValue('contentQuestionnaireUrl'),
                        "_blank",
                        "resizable=yes, scrollbars=yes, status=no, toolbar=no, menubar=no, location=no"
                      );
                    }
                    resolve();
                  } catch (e) {
                    resolve();
                  }
                });
                $('.swal-button.swal-button--confirm')[0].innerHTML = this.$t("fillOutQuestionaire");
                $('.swal-button.swal-button--cancel')[0].innerHTML = this.$t("end");
              });
            }
          })
          .then((action) => {
            if (redirectUrl) window.location.href = redirectUrl;
            self.backToMenuPage()
          });
        $('.swal-button.swal-button--confirm')[0].innerHTML = this.$t("end");
      }
    },
    checkNextTestSection(onlineTestSectionId) {
      let index = this.onlineTestSectionIds.findIndex((testSection) => {
        return testSection == onlineTestSectionId;
      });
      return index < this.onlineTestSectionIds.length - 1;
    },
    resetTimer() {
      if (this.$refs.timer) this.$refs.timer.reset();
    },
    async startTimer(limitTime) {
      this.$refs.timer.start(limitTime);
    },
    pauseTimer() {
      this.$refs.timer.pause();
    },
    restartTimer(limitTime) {
      this.$refs.timer.restart(limitTime);
    },
    timeOver() {
      this.$refs[`test-${this.testingSectionId}`][0].timeOver();
    },
    getRemainingTime() {
      return this.$refs.timer ? this.$refs.timer.getRemainingTime() : 0;
    },
    async close() {
      if (swal.getState().isOpen) return;

      if (this.page == 'test') {
        let params = {
          content: $(`<div class='swal-title'>${this.$t('endWithoutSubmitting')}</div>`)[0],
          buttons: [this.$t('no'), this.$t('yes')],
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }

        await swal(
          params
        ).then(response => {
          if (response) {
            this.backToMenuPage();
          }
        });
      } else {
        this.closeWindow();
      }
    },
    closeWindow() {
      if (API) {
        API.closeWindow(false);
      } else {
        this.backToMenuPage();
      }
    },
    getOnlineTestSections() {
      return API.getOnlineTestSections(this.onlineTest, this.mode);
    },
    resetTestPartQuestionAmount(questions) {
      if (!this.onlineTest.test_level.test_parts) return;
      this.onlineTest.test_level.test_parts.forEach(testPart => {
        let filteredQuestions = questions.filter(question => {
          return question.test_part_id === testPart.id;
        });
        testPart.question_amount = filteredQuestions.length;
      });
    },
    sequence(index) {
      if (this.$i18n.locale == 'en') {
        switch (index % 10) {
          case 1:
            return index + 'st';
          case 2:
            return index + 'nd';
          case 3:
            return index + 'rd';
          default:
            return index + 'th';
        }
      } else {
        return index;
      }
    },
  },
  computed: {
    testComponent() {
      switch (this.onlineTest.test.code) {
        case 'toeic':
          return 'TestToeic';
        default:
          return 'Test';
      }
    },
    testLanguage() {
      return this.languages.find(language => language.alias === this.testLanguageCode);
    },
    selectableTestLanguages() {
      if (!(this.onlineTest.options && this.onlineTest.options.selectable_language_codes)) return [];
      return this.languages.filter(language => $.inArray(language.alias, this.onlineTest.options.selectable_language_codes) > -1);
    },
    onlineTestSectionIds() {
      return API.getOnlineTestSectionIds(this.onlineTest, this.mode);
    },
    onlineTestSections() {
      return this.getOnlineTestSections();
    },
    availableTestSectionIds() {
      // if (this.mode === 'test' || this.privilegeType === 'times') {
      //   return this.mode === 'test' ? [this.untestedSectionIds[0]] : this.untestedSectionIds;
      // } else {
      //   return this.onlineTestSectionIds;
      // }
      if (this.mode === 'test') return [this.untestedSectionIds[0]];
      else return this.untestedSectionIds;
    },
    untestedSectionIds() {
      return API.getUntestedTestSectionIds(this.onlineTest, this.mode);
    },
    isIOS: () => {
      let ua = navigator.userAgent;
      let tmpIsIOS = /iPhone|iPad|iPod/i.test(ua);

      // Detects iPadOS 13
      let isiPad = ua.indexOf('iPad') > -1 || ua.indexOf('Macintosh') > -1 && 'ontouchend' in document;

      // if (tmpIsIOS || isiPad) $('#app').css('overflow-y', 'hidden'); // Online for local environment.

      return (tmpIsIOS || isiPad);
    },
  },
  watch: {
    // testLanguageCode(newVal) {
    //   this.$i18n.locale = newVal ? newVal : 'ja';
    // }
  }
}
</script>

<style lang="scss" scoped>
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

.container {
  max-width: 80%;
  font-size: 1.2rem;
  // height: 100vh;
  padding-left: 1rem;
  padding-right: 1rem;

  @media only screen and (max-width: 1000px) {
    max-width: 100%;
    width: 100%;
  }

  @media only screen and (max-width: 800px) {
    max-width: 100%;
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    max-width: 100%;
    width: 100%;
  }

  .fetched-data {
    height: 100%;
  }
}

.title {
  a {
    line-height: 1.2;
    font-size: 0.8rem;
  }
}


@media screen and (min-width: 601px) {
  .title {
    a {
      font-size: 2rem;
      white-space: nowrap;
    }
  }

  .container,
  .choices,
  .question-index {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1001px) {
  .title {
    a {
      font-size: 1.2vw;
      white-space: nowrap;
    }
  }

  .container,
  .question-index {
    font-size: 1.5vw;
  }
}

.close-button {
  position: absolute;
  right: 1rem;
  top: 20%;
  background-color: white;
  padding: 0 2vw;
  height: 60%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  // box-shadow: 5px 5px 20px rgba(0, 0, 0, .4);
  cursor: pointer;
  color: #ef5350;
  font-weight: bold;
  font-size: 15px;
  font-family: '-webkit-pictograph';

  ruby {
    top: 5px;
    position: relative;
  }
}
</style>