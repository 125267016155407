<template>
  <div class="menu d-flex row flex-wrap-wrap padding-top-0">
    <slot></slot>
    <div class="d-flex flex-wrap-wrap justify-content-space-around width-percentage-80">
      <MenuButton v-for="(onlineTestSection, index) in onlineTestSections" :key="index"
        :onlineTestSection="onlineTestSection" :onlineTestSectionAmount="onlineTestSections.length"
        :img="onlineTestSection.image ? (window.axios.defaults.baseURL ? window.axios.defaults.baseURL + onlineTestSection.image : onlineTestSection.image) : url"
        :privilegeType="privilegeType" :mode="mode" :availableTestSectionIds="availableTestSectionIds"
        @changePage="changePage" />
    </div>
  </div>
</template>

<script>

import MenuButton from './base/MenuButton';

export default {
  components: {
    MenuButton
  },
  props: {
    onlineTestSections: Array,
    privilegeType: String,
    mode: String,
    availableTestSectionIds: Array
  },
  data() {
    return {
      window,
      url: './assets/images/menu_button.png'
    }
  },
  methods: {
    changePage(page, onlineTestSectionId) {
      this.$emit('changePage', page, onlineTestSectionId);
    }
  }
}
</script>

<style lang="scss" scoped>
</style>