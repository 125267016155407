//  STG
// JLPTハーフ模試 N1（ランダム版）
// let packageId = 200;
// let courseId = 829;
// let contentId = 1118;
// let onlineTestId = 1;

// TOEIC® L&R TEST オンラインハーフ模試　A
// let packageId = 245;
// let courseId = 1044;
// let contentId = 1465;
// let onlineTestId = 26;

// TOEIC® L&R TEST オンライン模試　D
// let packageId = 239;
// let courseId = 1038;
// let contentId = 1459;
// let onlineTestId = 20;

// TOEIC® L&R TEST オンライン模試　E
// let packageId = 240;
// let courseId = 1039;
// let contentId = 1460;
// let onlineTestId = 21;

//　JLPT模試 N1復習問題
// let packageId = 253;
// let courseId = 1060;
// let contentId = 1502;
// let onlineTestId = 31;

//　JLPT模試 N2復習問題
// let packageId = 255;
// let courseId = 1075;
// let contentId = 1517;
// let onlineTestId = 45;

// JLPTハーフ模試 N1 ver2（固定版）SET-A
// let packageId = 215;
// let courseId = 1004;
// let contentId = 1410;
// let onlineTestId = 32;

// 合格模試N1セット1
// let packageId = 250;
// let courseId = 1064;
// let contentId = 1510;
// let onlineTestId = 33;

// 合格模試N1セット2
// let packageId = 250;
// let courseId = 1064;
// let contentId = 1511;
// let onlineTestId = 34;

// 合格模試N1セット3
// let packageId = 250;
// let courseId = 1064;
// let contentId = 1512;
// let onlineTestId = 35;

// 合格模試N2セット2
// let packageId = 250;
// let courseId = 1063;
// let contentId = 1509;
// let onlineTestId = 37;

// 合格模試N3セット1
// let packageId = 250;
// let courseId = 1052;
// let contentId = 1492;
// let onlineTestId = 30;

// 合格模試N3セット2
// let packageId = 250;
// let courseId = 1052;
// let contentId = 1507;
// let onlineTestId = 39;

// 合格模試N3セット3
// let packageId = 250;
// let courseId = 1052;
// let contentId = 1514;
// let onlineTestId = 40;

// 合格模試N4セット1
// let packageId = 250;
// let courseId = 1051;
// let contentId = 1491;
// let onlineTestId = 29;

// 合格模試N4セット2
// let packageId = 250;
// let courseId = 1051;
// let contentId = 1506;
// let onlineTestId = 41;

// 合格模試N4セット3
// let packageId = 250;
// let courseId = 1051;
// let contentId = 1515;
// let onlineTestId = 42;

// 合格模試N5セット1
// let packageId = 250;
// let courseId = 1047;
// let contentId = 1468;
// let onlineTestId = 28;

// 合格模試N5セット2
// let packageId = 250;
// let courseId = 1047;
// let contentId = 1505;
// let onlineTestId = 43;

// 合格模試N5セット3
// let packageId = 250;
// let courseId = 1047;
// let contentId = 1516;
// let onlineTestId = 44;

// 介護技能評価試験（ランダム版）
// let packageId = 259;
// let courseId = 1079;
// let contentId = 1521;
// let onlineTestId = 49;

// PROD
// JLPTハーフ模試 N1 ver2（固定版）SET-D
// let packageId = 263;
// let courseId = 518;
// let contentId = 881;
// let onlineTestId = 9;

// JLPTハーフ模試 N1 ver2（固定版）SET-D
// let packageId = 451;
// let courseId = 755;
// let contentId = 1117;
// let onlineTestId = 73;

// JLPTカスタム復習問題N4
let packageId = 454;
let courseId = 758;
let contentId = 1120;
let onlineTestId = 76;

// JLPTカスタム復習問題N5
// let packageId = 455;
// let courseId = 759;
// let contentId = 1121;
// let onlineTestId = 77;

let isTeacher = false;
let operationLanguage = 'ja';

const token = localStorage.getItem('student_token');
let cache, privilege;

async function fetchOnlineTestSectionActivity(
  onlineTestSectionId,
  mode,
  others
) {
  let onlineTestSectionActivity;

  await axios({
    method: 'POST',
    url: '/api/student/activities/online-test/online-test-section/fetch',
    data: {
      content_id: cache['contentId'],
      online_test_id: cache['onlineTestId'],
      online_test_section_id: onlineTestSectionId,
      mode,
      progress_text: {
        mode,
        test_language_code: others.testLanguageCode,
        display_ruby: others.displayRuby,
        choice_arrangement: others.choiceArrangement,
        remaining_time: others.remainingTime,
      },
      content_activity_id: cache['contentActivity']
        ? cache['contentActivity'].id
        : '',
      course_privilege_time_id: cache['coursePrivilegeTime']
        ? cache['coursePrivilegeTime'].id
        : '',
    },
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      onlineTestSectionActivity = response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return onlineTestSectionActivity;
}

function checkExpiredTime(packagePrivilegeTime) {
  return (
    !packagePrivilegeTime.license_corp_sale.expired_at ||
    new Date(
      packagePrivilegeTime.license_corp_sale.expired_at.replace(/-/g, '/') +
        '+0900'
    ) > new Date()
  );
}

async function checkPermission() {
  return [true, ''];
}

async function fetchOnlineTest(onlineTestId) {
  let onlineTest;

  await axios({
    method: 'GET',
    url: `/api/student/online-test/${onlineTestId}`,
    params: {},
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      onlineTest = response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return onlineTest;
}

async function fetchPackagePrivilege() {
  const options = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    url: `/api/student/packages/show?id=${packageId}`,
  };

  await axios(options)
    .then((response) => {
      privilege = response.data[0];
    })
    .catch((error) => {
      console.log(error.message);
    });
}

async function fetchQuestions(
  mode,
  choiceArrangement
) {
  return await axios({
    method: 'POST',
    url: '/api/student/questions/fetch',
    data: {
      content_id: cache['contentId'],
      online_test_section_id: cache['onlineTestSectionId'] ? cache['onlineTestSectionId'] : '', // For teacher
      online_test_section_activity_id: cache['onlineTestSectionActivity']
        ? cache['onlineTestSectionActivity'].id
        : '',
      mode,
      choice_arrangement: choiceArrangement,
      isTeacher: LMSGetValue('isTeacher'),
    },
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    return response.data;
  });
}

async function fetchQuestionsById(questionIds, onlineTestQuestionIds) {
  let questions;
  await axios({
    method: 'GET',
    url:
      `/api/student/questions/fetchQuestionsById?question_ids=${questionIds.join()}` +
      `&online_test_question_ids=${onlineTestQuestionIds.join()}` +
      `&online_test_section_activity_id=${cache['onlineTestSectionActivity'].id}` +
      `&handle_question=true`,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      questions = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
  return questions;
}

async function fetchQuestionTagsById(questionTagIds) {
  if (!questionTagIds) return [];
  let questionTags;
  await axios({
    method: 'GET',
    url: `/api/student/questions/fetchQuestionTagsById?question_tag_ids=${questionTagIds.join()}`,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      questionTags = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
  return questionTags;
}

function getCoursePrivilegeTimes(packagePrivilegeTimes, courseId) {
  if (!packagePrivilegeTimes) return null;
  let coursePrivilegeTimes = [];
  packagePrivilegeTimes.forEach((packagePrivilegeTime) => {
    let coursePrivilegeTime = packagePrivilegeTime.course_privilege_times.find(
      (coursePrivilegeTime) => coursePrivilegeTime.course_id == courseId
    );
    if (coursePrivilegeTime) coursePrivilegeTimes.push(coursePrivilegeTime);
  });
  return coursePrivilegeTimes;
}

function getCourseRemainingTimes(courseId) {
  if (!cache['remainingTimes']) return null;
  let incompletedPackagePrivilegeTimes = cache['packagePrivilegeTimes'].filter(
    (packagePrivilegeTime) => packagePrivilegeTime.completed_at === null
  );
  let remainingPackagePrivilegeTimesOfCourse =
    incompletedPackagePrivilegeTimes.filter((packagePrivilegeTime) => {
      return !packagePrivilegeTime.course_privilege_times.find(
        (coursePrivilegeTime) => coursePrivilegeTime.course_id == courseId
      );
    });
  return (
    remainingPackagePrivilegeTimesOfCourse.length + cache['remainingTimes']
  );
}

function getOperationLanguage() {
  return operationLanguage;
}

function getOptionFromCoursePrivilegeTime(item, optionName) {
  return item.content_activity &&
    item.content_activity.progress_text &&
    (item.content_activity.progress_text[optionName] ||
      item.content_activity.progress_text[optionName] === false)
    ? item.content_activity.progress_text[optionName]
    : '';
}

function getOptionFromPackagePrivilegeTime(item, optionName) {
  return item.license_corp_sale.options &&
    (item.license_corp_sale.options[optionName] ||
      item.license_corp_sale.options[optionName] === false)
    ? item.license_corp_sale.options[optionName]
    : '';
}

function getResult(questions, answers) {
  let result = [];
  questions.forEach((question, questionIndex) => {
    let temp = {};
    temp.question_id = question.id;
    switch (question.question_type_id) {
      case 1:
        temp.answer = [];
        question.question_choices.forEach((choice, choiceIndex) => {
          temp.answer.push({
            id: choice.id,
            is_checked: answers[questionIndex] == choiceIndex + 1,
          });
        });
        break;
      case 4:
        temp.answer = answers[questionIndex];
        break;
    }
    result.push(temp);
  });
  return result;
}

function getOnlineTestSections(onlineTest, mode) {
  let onlineTestSectionType = getOnlineTestSectionType(onlineTest, mode);
  return onlineTest.online_test_sections.filter(
    (onlineTestSection) => onlineTestSection.type === onlineTestSectionType
  );
}

function getOnlineTestSectionType(onlineTest, mode) {
  return onlineTest.options &&
    onlineTest.options.mode_section_method === 'separate'
    ? mode
    : 'share';
}

function getQuestionExplanationText(question, type) {
  let questionExplanation = question.question_explanations
    ? question.question_explanations.find((questionExplanation) => {
        return questionExplanation.type == type;
      })
    : null;
  return questionExplanation ? questionExplanation.text : '-';
}

function getProgressText() {
  return null;
}

function getTestedOnlineTestSectionIds(onlineTest, mode) {
  let testedSectionIds = [];
  let onlineTestSectionType = getOnlineTestSectionType(onlineTest, mode);
  if (
    cache['contentActivity'] &&
    cache['contentActivity'].online_test_activity
  ) {
    let onlineTestSectionActivities = cache[
      'contentActivity'
    ].online_test_activity.online_test_section_activities.filter(
      (onlineTestSectionActivity) =>
        onlineTestSectionActivity.online_test_section.type ===
        onlineTestSectionType
    );
    onlineTestSectionActivities.forEach(function (onlineTestSectionActivity) {
      if (onlineTestSectionActivity.completed_at)
        testedSectionIds.push(onlineTestSectionActivity.online_test_section.id);
    });
  }
  return testedSectionIds;
}

function getOnlineTestSectionIds(onlineTest, mode) {
  let arr = [];
  let onlineTestSections = getOnlineTestSections(onlineTest, mode);
  if (onlineTestSections) {
    onlineTestSections.forEach((onlineTestSection) => {
      arr.push(onlineTestSection.id);
    });
  }
  return arr;
}

function getUntestedTestSectionIds(onlineTest, mode) {
  let untestedTestSectionIds = [];
  let onlineTestSectionIds = getOnlineTestSectionIds(onlineTest, mode);
  let testedSectionIds = getTestedOnlineTestSectionIds(onlineTest, mode);
  for (let i = 0; i < onlineTestSectionIds.length; i++) {
    if ($.inArray(onlineTestSectionIds[i], testedSectionIds) < 0)
      untestedTestSectionIds.push(onlineTestSectionIds[i]);
  }
  return untestedTestSectionIds;
}

function initializeCache() {
  cache = new Object();
  cache['contentId'] = contentId;
  cache['courseId'] = courseId;
  cache['packageId'] = packageId;
  cache['onlineTestId'] = onlineTestId;
  cache['contentQuestionnaireUrl'] = '';
  cache['isTeacher'] = isTeacher;
  if (!isTeacher) {
    cache['studentId'] = privilege.corp_student_id;
    cache['mode'] =
      privilege && privilege.options ? privilege.options.mode : '';
    cache['sectionStartTimer'] =
      privilege &&
      privilege.options &&
      privilege.options.section_start_timer === false
        ? false
        : true;
    cache['sectionStartStandbyTime'] =
      privilege &&
      privilege.options &&
      privilege.options.section_start_standby_time !== undefined
        ? privilege.options.section_start_standby_time
        : 180;
    cache['displayRuby'] =
      privilege &&
      privilege.options &&
      privilege.options.display_ruby !== undefined
        ? privilege.options.display_ruby
        : '';
    cache['privilegeType'] = privilege ? privilege.type : 'term';
    cache['isPrivilegeAlive'] = privilege ? privilege.isAlive : false;
    cache['remainingTimes'] =
      privilege && privilege.type === 'times'
        ? privilege.remaining_times
        : undefined;
    cache['packagePrivilegeTimes'] = privilege
      ? privilege.package_privilege_times
      : null;
    cache['coursePrivilegeTimes'] = getCoursePrivilegeTimes(
      cache['packagePrivilegeTimes'],
      cache['courseId']
    );
  }
}

async function LMSCommit(
  onlineTestSectionActivityIds,
  mode,
  result,
  others = {}
) {
  let responseData = {
    success: true,
    message: '',
  };
  return responseData;
}

function LMSGetValue(varname) {
  if (!cache) return;
  return cache[varname];
}

function LMSSetValue(varname, varvalue) {
  cache[varname] = varvalue;
  return cache;
}

// async function recountTimes() {
//   privilege = await lmsVm.recountTimes();
//   initializeCache();
//   return privilege.course_privilege_time;
// }

async function recountTimes(mark = 'minus') {
  const token = localStorage.getItem('student_token');

  let self = this;
  await axios({
    method: 'POST',
    url: '/api/student/packages/recount-times',
    data: {
      privilege_id: privilege.id,
      // package_id: cache['packageId'],
      course_id: cache['courseId'],
      remainingTimes: privilege.remaining_times,
      mark: mark,
    },
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      new Promise((resolve, reject) => {
        privilege = response.data;
        if (window.parent && window.parent.opener) {
          try {
            window.parent.opener.document.recountTimes();
            console.log('Succeed in recounting times on parent page.');
            console.log('resolve');
            resolve();
          } catch {
            console.log('Error happens when recounting times on parent page.');
            reject();
          }
        } else {
          console.log('No recountTimes function on parent page.');
          reject();
        }
      });
      console.log(
        `使用可能回数が${response.data.remaining_times}回になりました。`
      );
    })
    .catch((error) => {
      console.log(error);
      swal(self.$t('pages.scorm.Base.warning.error')).then(function () {
        self.closeWindow();
      });
    });

  initializeCache();
  return privilege.course_privilege_time;
}

async function removeTemporarilySavedData(onlineTestSectionId) {
  let responseData = {
    success: false,
    message: '',
  };

  // let onlineTestSectionActivity = cache['coursePrivilegeTime']
  //                             .content_activity
  //                             .online_test_activity
  //                             .online_test_section_activities.find(onlineTestSectionActivity=>{
  //                               return onlineTestSectionActivity.online_test_section_id == onlineTestSectionId;
  //                             });

  await axios({
    method: 'POST',
    url: '/api/student/activities/online-test/online-test-section/temporarily-save/delete',
    data: {
      online_test_section_activity_id: cache['onlineTestSectionActivity'].id,
    },
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status == 200) {
        responseData.success = true;
      } else {
        responseData.success = false;
        responseData.message = response.data.message;
      }
    })
    .catch((error) => {
      console.log(error);
      responseData.success = false;
      responseData.message = error.response
        ? error.response.data.message
        : null;
    });

  return responseData;
}

function setConfirmBeforeLeave(val) {}

function setCoursePrivilegeTime(coursePrivilegeTime) {
  let packagePrivilegeTime = privilege.package_privilege_times.find(
    (packagePrivilegeTime) =>
      packagePrivilegeTime.id == coursePrivilegeTime.package_privilege_time_id
  );
  cache['packagePrivilegeTime'] = packagePrivilegeTime;
  cache['coursePrivilegeTime'] = coursePrivilegeTime;

  let timesEndWhen = getOptionFromPackagePrivilegeTime(
    packagePrivilegeTime,
    'times_end_when'
  );
  cache['timesEndWhen'] = timesEndWhen ? timesEndWhen : 'completed';
  cache['contentActivity'] = coursePrivilegeTime.content_activity;
  cache['mode'] = getOptionFromCoursePrivilegeTime(coursePrivilegeTime, 'mode')
    ? getOptionFromCoursePrivilegeTime(coursePrivilegeTime, 'mode')
    : privilege.options
    ? privilege.options.mode
    : '';

  cache['testLanguageCode'] = getOptionFromPackagePrivilegeTime(
    packagePrivilegeTime,
    'test_language_code'
  )
    ? getOptionFromPackagePrivilegeTime(
        packagePrivilegeTime,
        'test_language_code'
      )
    : getOptionFromCoursePrivilegeTime(
        coursePrivilegeTime,
        'test_language_code'
      );
  cache['displayRuby'] = getOptionFromPackagePrivilegeTime(
    packagePrivilegeTime,
    'display_ruby'
  )
    ? getOptionFromPackagePrivilegeTime(packagePrivilegeTime, 'display_ruby')
    : getOptionFromCoursePrivilegeTime(coursePrivilegeTime, 'display_ruby');
  cache['choiceArrangement'] = getOptionFromPackagePrivilegeTime(
    packagePrivilegeTime,
    'choice_arrangement'
  )
    ? getOptionFromPackagePrivilegeTime(
        packagePrivilegeTime,
        'choice_arrangement'
      )
    : getOptionFromCoursePrivilegeTime(
        coursePrivilegeTime,
        'choice_arrangement'
      );
}

async function temporarilySave(data) {
  let responseData = {
    success: false,
    message: '',
  };

  await axios({
    method: 'POST',
    url: '/api/student/activities/online-test/online-test-section/temporarily-save',
    data: {
      online_test_section_activity_id: cache['onlineTestSectionActivity'].id,
      temporarily_save_data: data,
    },
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status == 200) {
        responseData.success = true;
      } else {
        responseData.success = false;
        responseData.message = response.data.message;
      }
    })
    .catch((error) => {
      console.log(error);
      responseData.success = false;
      responseData.message = error.response
        ? error.response.data.message
        : null;
    });

  return responseData;
}

function translation(data, originalLanguageCode, languageCode) {
  if (!data) return '';
  let retrunData = data;

  if (languageCode !== originalLanguageCode && data.translations) {
    let translation = data.translations.find((translation) => {
      return translation.language.alias === languageCode;
    });

    if (translation) retrunData = translation;
  }

  return retrunData.text;
}

function translation_joined(data, originalLanguageCode, languageCode) {
  if (!data) return '';
  let retrunData = data;

  if (languageCode !== originalLanguageCode && data.translations) {
    let translation = data.translations.find((translation) => {
      return translation.language_code === languageCode;
    });

    if (translation) retrunData = translation;
  }

  return retrunData.text;
}

let API = {
  fetchOnlineTestSectionActivity,
  checkExpiredTime,
  checkPermission,
  fetchOnlineTest,
  fetchPackagePrivilege,
  fetchQuestions,
  fetchQuestionsById,
  fetchQuestionTagsById,
  getCourseRemainingTimes,
  getOnlineTestSections,
  getOptionFromCoursePrivilegeTime,
  getOptionFromPackagePrivilegeTime,
  getOperationLanguage,
  getProgressText,
  getQuestionExplanationText,
  getResult,
  getTestedOnlineTestSectionIds,
  getOnlineTestSectionIds,
  getUntestedTestSectionIds,
  initializeCache,
  LMSCommit,
  LMSGetValue,
  LMSSetValue,
  recountTimes,
  removeTemporarilySavedData,
  setConfirmBeforeLeave,
  setCoursePrivilegeTime,
  temporarilySave,
  translation,
  translation_joined,
};

export default API;
