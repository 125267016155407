<template>
  <div class="col s12 d-flex justify-content-center align-items-center margin-x-0"
    :class="onlineTestSectionAmount === 1 || onlineTestSectionAmount % 2 === 0 ? 'm5' : 'm4'">
    <div class="margin-0">
      <div class="card">
        <div class="card-image">
          <img :src="img">
        </div>
        <div class="card-content">
          <p class="tooltipped" :data-tooltip="onlineTestSection.description" data-position="bottom"
            v-html="onlineTestSection.description"></p>
        </div>
        <a v-if="isAvailable(onlineTestSection.id)" @click="startTest">
          <div class="card-action active">
            <i class="material-icons margin-right-3">play_circle_filled</i><span v-html="$t('startTest')" />
          </div>
        </a>
        <div v-else-if="isTested(onlineTestSection.id)" class="card-action cursor-unset" v-html="$t('testFinished')" />
        <div v-else class="card-action cursor-unset" v-html="$t('testUnavailable')" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    img: String,
    onlineTestSection: Object,
    onlineTestSectionAmount: Number,
    privilegeType: String,
    mode: String,
    availableTestSectionIds: Array
  },
  data() {
    return {
      window
    }
  },
  created() {
  },
  mounted() {
    $('.tooltipped').tooltip();
  },
  methods: {
    async startTest() {
      let checked = false;
      let swalText;

      if (this.mode === 'test') {
        let params = {
          title: this.$t('wantToStartTest'),
          buttons: [this.$t('no'), this.$t('yes')],
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }

        await swal(
          params
        ).then(async response => {
          if (response) {
            $('.tooltipped').tooltip('destroy');
            [checked, swalText] = await API.checkPermission();
          }
        });

      } else {
        [checked, swalText] = await API.checkPermission();
      }

      if (checked) {
        if (this.privilegeType === 'times') {
          let packagePrivilegeTime = API.LMSGetValue('packagePrivilegeTime');
          if (!API.checkExpiredTime(packagePrivilegeTime)) {
            swalText = this.$t('expirationDateOfThisTimePassed')
            checked = false;
          } else checked = true;
        }
      }

      if (checked) this.$emit('changePage', 'test', this.onlineTestSection.id);
      else if (swalText) {
        let params = {
          title: swalText,
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }
        swal(params);
      }
    },
    isAvailable(id) {
      return $.inArray(id, this.availableTestSectionIds) > -1;
    },
    isTested(id) {
      return $.inArray(id, this.testedSectionIds) > -1;
    }
  },
  computed: {
    testedSectionIds() {
      if (API) {
        if (!this.onlineTest) return [];
        return API.getTestedOnlineTestSectionIds(this.onlineTest, this.mode);
      } else {
        return [];
      }
    },
  },
  destroyed() {
    $('.tooltipped').tooltip('close');
  }
}
</script>

<style lang="scss" scoped>
.card-title {
  color: #ffab40 !important;
  background-color: #e9d1b261;
  // border-radius: 20px;
  padding: 0.5rem !important;
  width: 100%;
  max-height: 5rem;
  line-height: 2;
  display: -webkit-box;
  font-size: 1.2rem !important;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.card-action {
  color: #ffab40;
  border-radius: 0 0 2px 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    font-weight: bold;

    &::after {
      background-color: #e91e63;
      width: 4rem;
      height: 4rem;
      border-radius: 100% 100% 0 100%;
      position: absolute;
      top: -2rem;
      left: -2rem;
      color: white;
      transform: rotate(0deg);
      box-shadow: 1px 1px 3px 1px #80808080;
      border: 2px solid white;
      content: "Click Here!";
      padding: 10px;
      font-size: 1rem;
      line-height: 1.2;
    }
  }
}

.card-action:hover {
  color: #ffd8a6;
  cursor: pointer;
}

.card-content {
  height: 10rem;
  font-size: 1.2rem;

  p {
    text-align: start;
    color: gray;
    line-height: unset;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

@media only screen and (min-width: 601px) {
  .row .col.m7 {
    width: 80%;
  }
}
</style>
