let sectionStartRemainingTime;

function getSectionStartRemainingTime() {
  return sectionStartRemainingTime;
}

function setSectionStartRemainingTime(limitTime) {
  return sectionStartRemainingTime = limitTime;
}

function getUrl(path) {
  return `${window.axios.defaults.baseURL ? window.axios.defaults.baseURL : ''}${path}`;
}

function isEmptyObject(object) {
  for (var key in object) {
    if (object.hasOwnProperty(key))
      return false;
  }

  return true;
}

async function swalCountToStart(limitTime, title, text, button, vm) {

  sectionStartRemainingTime = limitTime;
  let startTime = new Date();

  let timer = setInterval(async ()=>{
    let currentElapsedTime = Math.round((new Date() - startTime) / 1000);
    if (currentElapsedTime > parseInt(limitTime) + 5) {
      clearInterval(timer);
      vm.backToMenuPage();
      await swal(
        {
          content: $(`<span>${vm.$t('standbyTimeExceeded')}</span>`)[0],
          icon: "warning",
          button: button,
          closeOnClickOutside: false,
          closeOnEsc: false,
          timer: 3000,
        }
      );
    } else if (currentElapsedTime >= parseInt(limitTime)) {
      clearInterval(timer);
    } else {
      sectionStartRemainingTime = limitTime - currentElapsedTime;
      $('.swal-text').html(vm.$t(text, {n:sectionStartRemainingTime}));
    }
  }, 1000);

  await new Promise(resolve => {
    swal(
      {
        title: title,
        text: `${sectionStartRemainingTime}秒後自動スタートします。`,
        icon: "warning",
        button: button,
        closeOnClickOutside: false,
        closeOnEsc: false,
        timer: limitTime * 1000,
      }
    ).then(()=>{
      clearInterval(timer);
      resolve();
    });
    $('.swal-text').html(vm.$t(text, {n:sectionStartRemainingTime}));
  })
}

async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  getSectionStartRemainingTime,
  setSectionStartRemainingTime,
  getUrl,
  isEmptyObject,
  sleep,
  swalCountToStart
}
