let token, cache;

const privilege = {};
const lmsVm = {};
let readyCallback = null;

class API {
    init() {
        this.postMessage("onlineTest.start");
        return new Promise(resolve => {
            readyCallback = resolve;
            resolve();
        });
    }

    /**
     * Handle message from parent window
     * @param {Event} evt
     */
    handleMessage = (evt) => {
        const {data} = evt;
        if (!data) {
            return;
        }
        const {type, payload} = data;
        if (!type || !type.startsWith("onlineTest.")) {
            return;
        }
        switch (type) {
            case 'onlineTest.init':
                this._init(payload);
                break;
            default:
                console.log(`unhandled event`, evt);
        }
    }

    _init(data) {
        Object.assign(lmsVm, data);
        readyCallback();
    }

    postMessage(type, payload) {
        if (window.parent === window) {
            return;
        }
        window.parent.postMessage({
            type,
            payload,
        }, "*");
    }

    async fetchAllLanguages() {
        return [
            {
                "id": 1,
                "name": "\u65e5\u672c\u8a9e",
                "alias": "ja"
            },
            {
                "id": 2,
                "name": "English",
                "alias": "en"
            },
            {
                "id": 3,
                "name": "Ti\u1ebfng Vi\u1ec7t",
                "alias": "vi"
            },
            {
                "id": 4,
                "name": "\u4e2d\u6587\uff08\u7b80\u4f53\uff09",
                "alias": "zh"
            },
            {
                "id": 5,
                "name": "\u4e2d\u6587\uff08\u7e41\u9ad4\uff09",
                "alias": "tw"
            },
            {
                "id": 6,
                "name": "\u1019\u103c\u1014\u103a\u1019\u102c\u1018\u102c\u101e\u102c",
                "alias": "my"
            },
            {
                "id": 7,
                "name": "Bahasa indonesia",
                "alias": "id"
            },
            {
                "id": 8,
                "name": "\ud55c\uad6d\uc5b4",
                "alias": "ko"
            },
            {
                "id": 9,
                "name": "Espa\u00f1ol",
                "alias": "es"
            },
            {
                "id": 11,
                "name": "\u0e20\u0e32\u0e29\u0e32\u0e44\u0e17\u0e22",
                "alias": "th"
            },
            {
                "id": 12,
                "name": "\u0928\u0947\u092a\u093e\u0932\u0940",
                "alias": "ne"
            },
            {
                "id": 13,
                "name": "Tagalog",
                "alias": "tl"
            }
        ]
    }

    initializeCache() {
        cache = {};
        cache['contentId'] = lmsVm.contentId;
        cache['courseId'] = lmsVm.courseId;
        cache['packageId'] = lmsVm.packageId;
        cache['onlineTestId'] = lmsVm.onlineTestId;
        cache['contentQuestionnaireUrl'] = lmsVm.contentQuestionnaireUrl;
        cache['isTeacher'] = lmsVm.isTeacher;
        if (!lmsVm.isTeacher) {
            cache['studentId'] = privilege.corp_student_id;
            cache['mode'] =
                privilege && privilege.options ? privilege.options.mode : '';
            cache['sectionStartTimer'] =
                !(privilege &&
                    privilege.options &&
                    privilege.options.section_start_timer === false);
            cache['sectionStartStandbyTime'] =
                privilege &&
                privilege.options &&
                privilege.options.section_start_standby_time !== undefined
                    ? privilege.options.section_start_standby_time
                    : 180;
            cache['displayRuby'] =
                privilege &&
                privilege.options &&
                privilege.options.display_ruby !== undefined
                    ? privilege.options.display_ruby
                    : '';
            cache['privilegeType'] = privilege ? privilege.type : 'term';
            cache['isPrivilegeAlive'] = privilege ? privilege.isAlive : false;
            cache['remainingTimes'] =
                privilege && privilege.type === 'times'
                    ? privilege.remaining_times
                    : undefined;
            cache['packagePrivilegeTimes'] = privilege
                ? privilege.package_privilege_times
                : null;
            cache['coursePrivilegeTimes'] = this.getCoursePrivilegeTimes(
                cache['packagePrivilegeTimes'],
                cache['courseId']
            );
        }
    }

    async fetchOnlineTestSectionActivity(
        onlineTestSectionId,
        mode,
        others
    ) {
        let onlineTestSectionActivity;

        // await axios({
        //     method: 'POST',
        //     url: '/api/student/activities/online-test/online-test-section/fetch',
        //     data: {
        //         content_id: cache['contentId'],
        //         online_test_id: cache['onlineTestId'],
        //         online_test_section_id: onlineTestSectionId,
        //         mode,
        //         progress_text: {
        //             mode,
        //             test_language_code: others.testLanguageCode,
        //             display_ruby: others.displayRuby,
        //             choice_arrangement: others.choiceArrangement,
        //             remaining_time: others.remainingTime,
        //         },
        //         content_activity_id: cache['contentActivity']
        //             ? cache['contentActivity'].id
        //             : '',
        //         course_privilege_time_id: cache['coursePrivilegeTime']
        //             ? cache['coursePrivilegeTime'].id
        //             : '',
        //     },
        //     headers: {
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${token}`,
        //     },
        // })
        //     .then((response) => {
        //         onlineTestSectionActivity = response.data;
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });

        return onlineTestSectionActivity;
    }

    getCoursePrivilegeTimes(packagePrivilegeTimes, courseId) {
        if (!packagePrivilegeTimes) return null;
        let coursePrivilegeTimes = [];
        packagePrivilegeTimes.forEach((packagePrivilegeTime) => {
            let coursePrivilegeTime = packagePrivilegeTime.course_privilege_times.find(
                (coursePrivilegeTime) => coursePrivilegeTime.course_id === courseId
            );
            if (coursePrivilegeTime) coursePrivilegeTimes.push(coursePrivilegeTime);
        });
        return coursePrivilegeTimes;
    }

    getCourseRemainingTimes(courseId) {
        if (!cache['remainingTimes']) return null;
        let incompletedPackagePrivilegeTimes = cache['packagePrivilegeTimes'].filter(
            (packagePrivilegeTime) => packagePrivilegeTime.completed_at === null
        );
        let remainingPackagePrivilegeTimesOfCourse =
            incompletedPackagePrivilegeTimes.filter((packagePrivilegeTime) => {
                return !packagePrivilegeTime.course_privilege_times.find(
                    (coursePrivilegeTime) => coursePrivilegeTime.course_id === courseId
                );
            });
        return (
            remainingPackagePrivilegeTimesOfCourse.length + cache['remainingTimes']
        );
    }

    async checkPermission() {
        // when the page is loaded, the permission is correct!
        return [true, ''];
        // if (lmsVm.isTeacher) return [true, ''];
        // let contentId = cache['contentId'];
        // let checked;
        // let swalText = '';
        //
        // const token = localStorage.getItem('student_token');
        //
        // await axios({
        //     method: 'GET',
        //     url: `/api/student/packages/courses/contents/${contentId}/permission`,
        //     headers: {
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${token}`,
        //     },
        // })
        //     .then((response) => {
        //         checked = true;
        //     })
        //     .catch((error) => {
        //         switch (error.response.data.message) {
        //             case 'free-trial-unavailable':
        //                 lmsVm.freeTrialWarning();
        //                 return;
        //             case 'license-expire':
        //                 swalText = lmsVm.$t('pages.scorm.Base.warning.licenseExpireError');
        //                 break;
        //             case 'license-not-started':
        //                 swalText = lmsVm.$t(
        //                     'pages.scorm.Base.warning.licenseNotStartedError'
        //                 );
        //                 break;
        //             case 'wrong-use-order':
        //                 swalText = lmsVm.$t('pages.scorm.Base.warning.wrongUseOrderError');
        //                 break;
        //             default:
        //                 swalText = lmsVm.$t(
        //                     'corporateAdmin.components.packages.transactionDetails.confirmationModal.errorTryAgain'
        //                 );
        //                 break;
        //         }
        //
        //         checked = false;
        //     });
        //
        // return [checked, swalText];
    }

    checkExpiredTime(packagePrivilegeTime) {
        return (
            !packagePrivilegeTime.license_corp_sale.expired_at ||
            new Date(
                moment(packagePrivilegeTime.license_corp_sale.expired_at + '+0900')
            ) > new Date()
        );
    }

    /**
     * @param {string} key
     * @returns {*}
     * @constructor
     */
    LMSGetValue(key) {
        if (!cache) return;
        return cache[key];
    }

    /**
     * @param {string} key
     * @param {Object} value
     * @returns {*}
     * @constructor
     */
    LMSSetValue(key, value) {
        cache[key] = value;
        return cache;
    }

    async LMSCommit(
        onlineTestSectionActivityIds,
        mode,
        result,
        others = {}
    ) {
        // const token = localStorage.getItem('student_token');

        let responseData = {
            success: false,
            message: '',
        };

        // if (lmsVm.isTeacher) responseData.success = true;
        // else {
        //     await axios({
        //         method: 'POST',
        //         url: '/api/student/activities/online-test/commit',
        //         data: {
        //             // content_id: cache['contentId'],
        //             // online_test_id: onlineTestId,
        //             // online_test_section_id: onlineTestSectionId,
        //             online_test_section_activity_ids: onlineTestSectionActivityIds,
        //             result,
        //             progress_text: {
        //                 mode,
        //                 test_language_code: others.testLanguageCode,
        //                 display_ruby: others.displayRuby,
        //                 choice_arrangement: others.choiceArrangement,
        //                 remaining_time: others.remainingTime,
        //             },
        //             content_activity_id: cache['contentActivity']
        //                 ? cache['contentActivity'].id
        //                 : '',
        //             course_privilege_time_id: cache['coursePrivilegeTime']
        //                 ? cache['coursePrivilegeTime'].id
        //                 : '',
        //         },
        //         headers: {
        //             Accept: 'application/json',
        //             Authorization: `Bearer ${token}`,
        //         },
        //     })
        //         .then((response) => {
        //             if (response.status == 200) {
        //                 cache['contentActivity'] = response.data;
        //                 responseData.success = true;
        //                 if (cache['contentActivity']['is_completed'])
        //                     responseData.url = `/student/activities/${cache['contentActivity']['id']}/online-test`;
        //             } else {
        //                 responseData.success = false;
        //                 responseData.message = response.data.message;
        //             }
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //             responseData.success = false;
        //             responseData.message = error.response
        //                 ? error.response.data.message
        //                 : null;
        //         });
        // }

        return responseData;
    }

    async fetchOnlineTest(onlineTestId) {
        // online test data already loaded when initialize
        let onlineTest = [];

        // await axios({
        //     method: 'GET',
        //     url: `/api/student/online-test/${onlineTestId}`,
        //     params: {},
        //     headers: {
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${token}`,
        //     },
        // }).then((response) => {
        //     onlineTest = response.data;
        // });
        //
        cache['onlineTest'] = onlineTest;
        return onlineTest;
    }

    async fetchPackagePrivilege() {
        // privilege already loaded when initialize
        // if (lmsVm.isTeacher) privilege = null;
        // else {
        //     await lmsVm.$store.dispatch(
        //         'studentPackagesState/setSelectedPackage',
        //         lmsVm.packageId
        //     );
        //     privilege = lmsVm.$store.getters['studentPackagesState/getSelectedPackage'];
        // }
    }

    async fetchQuestions(
        onlineTestSectionId,
        mode,
        choiceArrangement
    ) {
        return await axios({
            method: 'POST',
            url: '/api/student/questions/fetch',
            data: {
                content_id: cache['contentId'],
                online_test_section_id: onlineTestSectionId, // For teacher
                online_test_section_activity_id: cache['onlineTestSectionActivity']
                    ? cache['onlineTestSectionActivity'].id
                    : '',
                mode,
                choice_arrangement: choiceArrangement,
                isTeacher: API.LMSGetValue('isTeacher'),
            },
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            return response.data;
        });
    }

    async fetchQuestionsById(questionIds, onlineTestQuestionIds) {
        let questions;
        await axios({
            method: 'GET',
            url:
                `/api/student/questions/fetchQuestionsById?question_ids=${questionIds.join()}` +
                `&online_test_question_ids=${onlineTestQuestionIds.join()}` +
                `&online_test_section_activity_id=${cache['onlineTestSectionActivity'].id}` +
                `&handle_question=true`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                questions = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
        return questions;
    }

    async fetchQuestionTagsById(questionTagIds) {
        let questionTags;
        await axios({
            method: 'GET',
            url: `/api/student/questions/fetchQuestionTagsById?question_tag_ids=${questionTagIds.join()}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                questionTags = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
        return questionTags;
    }

    getResult(questions, answers) {
        let result = [];
        questions.forEach((question, questionIndex) => {
            let temp = {};
            temp.question_id = question.id;
            switch (question.question_type_id) {
                case 1:
                    temp.answer = [];
                    question.question_choices.forEach((choice, choiceIndex) => {
                        temp.answer.push({
                            id: choice.id,
                            is_checked: answers[questionIndex] === choiceIndex + 1,
                        });
                    });
                    break;
                case 4:
                    temp.answer = answers[questionIndex];
                    break;
            }
            result.push(temp);
        });
        return result;
    }

    closeWindow() {
        lmsVm.closeWindow();
    }

    async recountTimes(mark = 'minus') {
        const token = localStorage.getItem('student_token');

        let self = this;
        await axios({
            method: 'POST',
            url: '/api/student/packages/recount-times',
            data: {
                privilege_id: privilege.id,
                // package_id: cache['packageId'],
                course_id: cache['courseId'],
                remainingTimes: privilege.remaining_times,
                mark: mark,
            },
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                new Promise((resolve, reject) => {
                    privilege = response.data;
                    if (window.parent && window.parent.opener) {
                        try {
                            window.parent.opener.document.recountTimes();
                            console.log('Succeed in recounting times on parent page.');
                            console.log('resolve');
                            resolve();
                        } catch {
                            console.log('Error happens when recounting times on parent page.');
                            reject();
                        }
                    } else {
                        console.log('No recountTimes on parent page.');
                        reject();
                    }
                });
                console.log(
                    `使用可能回数が${response.data.remaining_times}回になりました。`
                );
            })
            .catch((error) => {
                console.log(error);
                swal(self.$t('pages.scorm.Base.warning.error')).then(function () {
                        self.closeWindow();
                    }
                )
                ;
            });

        API.initializeCache();
        return privilege.course_privilege_time;
    }

    setConfirmBeforeLeave(val) {
        lmsVm.confirmBeforeLeave = val;
    }

    setCoursePrivilegeTime(coursePrivilegeTime) {
        let packagePrivilegeTime = privilege.package_privilege_times.find(
            (packagePrivilegeTime) =>
                packagePrivilegeTime.id === coursePrivilegeTime.package_privilege_time_id
        );
        cache['packagePrivilegeTime'] = packagePrivilegeTime;
        cache['coursePrivilegeTime'] = coursePrivilegeTime;

        let timesEndWhen = getOptionFromPackagePrivilegeTime(
            packagePrivilegeTime,
            'times_end_when'
        );
        cache['timesEndWhen'] = timesEndWhen ? timesEndWhen : 'completed';
        cache['contentActivity'] = coursePrivilegeTime.content_activity;
        cache['mode'] = getOptionFromCoursePrivilegeTime(coursePrivilegeTime, 'mode')
            ? getOptionFromCoursePrivilegeTime(coursePrivilegeTime, 'mode')
            : privilege.options
                ? privilege.options.mode
                : '';

        cache['testLanguageCode'] = getOptionFromPackagePrivilegeTime(
            packagePrivilegeTime,
            'test_language_code'
        )
            ? getOptionFromPackagePrivilegeTime(
                packagePrivilegeTime,
                'test_language_code'
            )
            : getOptionFromCoursePrivilegeTime(
                coursePrivilegeTime,
                'test_language_code'
            );
        cache['displayRuby'] = getOptionFromPackagePrivilegeTime(
            packagePrivilegeTime,
            'display_ruby'
        )
            ? getOptionFromPackagePrivilegeTime(packagePrivilegeTime, 'display_ruby')
            : getOptionFromCoursePrivilegeTime(coursePrivilegeTime, 'display_ruby');
        cache['choiceArrangement'] = getOptionFromPackagePrivilegeTime(
            packagePrivilegeTime,
            'choice_arrangement'
        )
            ? getOptionFromPackagePrivilegeTime(
                packagePrivilegeTime,
                'choice_arrangement'
            )
            : getOptionFromCoursePrivilegeTime(
                coursePrivilegeTime,
                'choice_arrangement'
            );
    }

    getUntestedTestSectionIds(onlineTest, mode) {
        let untestedTestSectionIds = [];
        let testSectionIds = getOnlineTestSectionIds(onlineTest, mode);
        let testedSectionIds = getTestedOnlineTestSectionIds(onlineTest, mode);
        for (let i = 0; i < testSectionIds.length; i++) {
            if ($.inArray(testSectionIds[i], testedSectionIds) < 0)
                untestedTestSectionIds.push(testSectionIds[i]);
        }
        return untestedTestSectionIds;
    }

    getProgressText() {
        return cache['contentActivity']
            ? cache['contentActivity'].progress_text
            : null;
    }

    getOnlineTestSections(onlineTest, mode) {
        let onlineTestSectionType = getOnlineTestSectionType(onlineTest, mode);
        return onlineTest.online_test_sections.filter(
            (onlineTestSection) => onlineTestSection.type === onlineTestSectionType
        );
    }

    getOnlineTestSectionType(onlineTest, mode) {
        return onlineTest &&
        onlineTest.options &&
        onlineTest.options.mode_section_method === 'separate'
            ? mode
            : 'share';
    }

    getOperationLanguage() {
        return lmsVm.language;
    }

    getOptionFromPackagePrivilegeTime(item, optionName) {
        return item.license_corp_sale.options &&
        (item.license_corp_sale.options[optionName] ||
            item.license_corp_sale.options[optionName] === false)
            ? item.license_corp_sale.options[optionName]
            : '';
    }

    getOptionFromCoursePrivilegeTime(item, optionName) {
        return item.content_activity &&
        item.content_activity.progress_text &&
        (item.content_activity.progress_text[optionName] ||
            item.content_activity.progress_text[optionName] === false)
            ? item.content_activity.progress_text[optionName]
            : '';
    }

    getOnlineTestSectionIds(onlineTest, mode) {
        let arr = [];
        let onlineTestSections = getOnlineTestSections(onlineTest, mode);
        if (onlineTestSections) {
            onlineTestSections.forEach((onlineTestSection) => {
                arr.push(onlineTestSection.id);
            });
        }
        return arr;
    }

    getTestedOnlineTestSectionIds(onlineTest, mode) {
        let testedSectionIds = [];
        let onlineTestSectionType = getOnlineTestSectionType(onlineTest, mode);
        if (
            cache['contentActivity'] &&
            cache['contentActivity'].online_test_activity
        ) {
            let onlineTestSectionActivities = cache[
                'contentActivity'
                ].online_test_activity.online_test_section_activities.filter(
                (onlineTestSectionActivity) =>
                    onlineTestSectionActivity.online_test_section.type ===
                    onlineTestSectionType
            );
            onlineTestSectionActivities.forEach(function (onlineTestSectionActivity) {
                if (onlineTestSectionActivity.completed_at)
                    testedSectionIds.push(onlineTestSectionActivity.online_test_section.id);
            });
        }
        return testedSectionIds;
    }

    hasObject(arr, id) {
        let flag = false;

        arr.forEach((item) => {
            if (item.id === id) flag = true;
        });

        return flag;
    }

    async temporarilySave(data) {
        let responseData = {
            success: false,
            message: '',
        };

        await axios({
            method: 'POST',
            url: '/api/student/activities/online-test/online-test-section/temporarily-save',
            data: {
                online_test_section_activity_id: cache['onlineTestSectionActivity'].id,
                temporarily_save_data: data,
            },
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    responseData.success = true;
                } else {
                    responseData.success = false;
                    responseData.message = response.data.message;
                }
            })
            .catch((error) => {
                console.log(error);
                responseData.success = false;
                responseData.message = error.response
                    ? error.response.data.message
                    : null;
            });

        return responseData;
    }

    async removeTemporarilySavedData(onlineTestSectionId) {
        let responseData = {
            success: false,
            message: '',
        };

        let onlineTestSectionActivity = cache[
            'coursePrivilegeTime'
            ].content_activity.online_test_activity.online_test_section_activities.find(
            (onlineTestSectionActivity) => {
                return (
                    onlineTestSectionActivity.online_test_section_id === onlineTestSectionId
                );
            }
        );

        await axios({
            method: 'POST',
            url: '/api/student/activities/online-test/online-test-section/temporarily-save/delete',
            data: {
                online_test_section_activity_id: onlineTestSectionActivity.id,
            },
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    responseData.success = true;
                } else {
                    responseData.success = false;
                    responseData.message = response.data.message;
                }
            })
            .catch((error) => {
                console.log(error);
                responseData.success = false;
                responseData.message = error.response
                    ? error.response.data.message
                    : null;
            });

        return responseData;
    }

    translation(data, originalLanguageCode, languageCode) {
        if (!data) return '';
        let retrunData = data;

        if (languageCode !== originalLanguageCode && data.translations) {
            let translation = data.translations.find((translation) => {
                return translation.language.alias === languageCode;
            });

            if (translation) retrunData = translation;
        }

        return retrunData.text;
    }

    translation_joined(data, originalLanguageCode, languageCode) {
        if (!data) return '';
        let retrunData = data;

        if (languageCode !== originalLanguageCode && data.translations) {
            let translation = data.translations.find((translation) => {
                return translation.language_code === languageCode;
            });

            if (translation) retrunData = translation;
        }

        return retrunData.text;
    }
}

export const api = new API();