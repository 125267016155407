<template>
  <div class="height-percentage-100">
    <div v-if="!isFetchingData" class="height-percentage-100">
      <div v-if="testStarted" class="test-started">
        <div class="test-container">
          <div v-if="mode == 'test' && audioUrlList.length > 0" class="overall-audio-container">
            <audio ref="overall-audio" id="overall-audio" class="overall-audio"
              :src="getUrl(audioUrlList[playingQuestionIndex][playingAudioIndex])" controlsList="nodownload"
              type="audio/mpeg" preload="auto" />
          </div>
          <Question :key="inQuestionNumber" ref="question"
            :groupQuestionDisplayMethod="groupQuestionDisplayMethod" :question="currQuestion" :questionNumber="inQuestionNumber"
            :questionTotalAmount="questions.length" :firstQuestionNumberOfCurrPart="firstQuestionNumberOfCurrPart"
            :testLanguageCode="testLanguageCode" :part="testPart" :testDirection="testDirection" :questions="questions"
            :mode="mode" :hide="isAnswerSheetOpen" />
          <AnswerSheet key="answerSheet" ref="answerSheet" :questions="questions" :question="currQuestion"
            :questionNumber="inQuestionNumber"
            :firstQuestionNumberOfCurrPart="firstQuestionNumberOfCurrPart" :testPart="testPart"
            :hide="!isAnswerSheetOpen" />
          <template v-if="mode === 'practice'">
            <div class="side-button" name="backToMenuPage" style="bottom:10vh;">
              <a class="btn yellow"><i class="material-icons">home</i></a>
              <span class="text" @click="isSideButtonAlive['backToMenuPage'] ? swalBackToMenuPage($event) : ''"
                v-html="$t('backToMenuPage')"></span>
            </div>
            <div class="side-button" name="temporarilySave" style="bottom:20vh;">
              <a class="btn blue"><i class="material-icons">save</i></a>
              <span class="text" @click="isSideButtonAlive['temporarilySave'] ? temporarilySave($event) : ''"
                v-html="$t('temporarilySave')"></span>
            </div>
            <div v-if="onlineTestSection.time > 0" class="side-button" name="pause" style="bottom:30vh;">
              <a class="btn green"><i class="material-icons" style="width:1em;">{{ testPaused ? 'play_circle_filled' :
                'pause_circle_filled' }}e</i></a>
              <span class="text" @click="isSideButtonAlive['pause'] ? switchTimer($event) : ''"
                v-html="testPaused ? $t('restartTest') : $t('pause')"></span>
            </div>
          </template>
        </div>
        <Footer ref="footer" :questions="questions" :prevQuestionNumber="prev()" :nextQuestionNumber="next()"
          @submit="commit()" />
        <!-- <div v-if="mode === 'practice'" class="fixed-action-btn click-to-toggle direction-top">
          <a class="btn-floating btn-large red">
            <i class="large material-icons">menu</i>
          </a>
          <ul>
            <li v-if="mode !== 'test'">
              <a class="btn-floating yellow darken-1 tooltipped" data-position="left" :data-tooltip="$t('backToMenuPage')"
                @click="$emit('close')">
                <i class="material-icons">home</i>
              </a>
            </li>
            <li>
              <a class="btn-floating green tooltipped switch-timer" data-position="left"
                :data-tooltip="testPaused ? $t('restartTest') : $t('pause')" @click="switchTimer">
                <i class="material-icons">{{ testPaused ? 'play_circle_filled' : 'pause_circle_filled' }}</i>
              </a>
            </li>
          </ul>
        </div> -->
        <div class="answerSheet-btn">
          <a class="btn-floating btn-large blue" @click="togglePopupAnswerSheet()">
            <i class="large material-icons">sync</i>
          </a>
        </div>
      </div>
      <a class="left-button"
        :class="(isQuestionListOpen ? 'button-hidden ' : '') + (testPart.code === getTestPartOfQuestion(questions[0])['code'] ? 'button-disabled' : '')"
        @click="inQuestionNumber = prevPart()">
        <span class="long-label">Previous Part</span>
        <span class="short-label">Part</span>
      </a>
      <a class="right-button"
        :class="(isQuestionListOpen ? 'button-hidden ' : '') + (testPart.code === getTestPartOfQuestion(questions[questions.length - 1])['code'] ? 'button-disabled' : '')"
        @click="inQuestionNumber = nextPart()">
        <span class="long-label">Next Part</span>
        <span class="short-label">Part</span>
      </a>
    </div>
    <div v-else class="fetching-data">
      <PreloaderCircle />
    </div>
  </div>
</template>

<script>
import Question from './Question.vue';
import AnswerSheet from './AnswerSheet.vue';
import Footer from './Footer.vue';
import PreloaderCircle from '../base/PreloaderCircle';
import helpers from '../../js/utils/helpers';

export default {
  components: {
    Question,
    AnswerSheet,
    Footer,
    PreloaderCircle
  },
  props: {
    groupQuestionDisplayMethod: String,
    onlineTestId: Number,
    onlineTestSectionId: [Number, String],
    onlineTestSection: Object,
    testDirections: Array,
    testParts: Array,
    testLanguageCode: String,
    privilegeType: String,
    mode: String,
    sectionStartTimer: Boolean,
    sectionStartStandbyTime: Number,
    displayRuby: [Boolean, String],
    choiceArrangement: String,
    restoreFromSaveData: Boolean,
    saveDataName: String,
    saveData: Object,
    commitImmediately: Boolean,
    isIOS: Boolean,
  },
  data() {
    return {
      testStarted: false,
      testPaused: false,
      questions: [],
      audioUrlList: [],
      // answers: [],
      inQuestionNumber: 1,
      isFetchingData: true,
      isResultCommitted: false,
      sideMenuButton: {},
      playingQuestionIndex: 0,
      playingAudioIndex: 0,
      isQuestionListOpen: false,
      openedTestDirections: [],
      isAnswerSheetOpen: false,
      isSideButtonAlive: {
        "backToMenuPage": false,
        "temporarilySave": false,
        "pause": false
      },
    }
  },
  created() {
    this.init();
    $('head').append('<style type="text/css">strong{font-weight:bold}</style>');
    if (API) API.setConfirmBeforeLeave(true);
  },
  methods: {
    async init() {
      this.isFetchingData = true;
      this.testStarted = false;

      let contentActivity;
      let onlineTestSectionActivity;

      // if (this.privilegeType === 'times') {
      let coursePrivilegeTime = API.LMSGetValue('coursePrivilegeTime');
      contentActivity = coursePrivilegeTime.content_activity;
      if (contentActivity)
        onlineTestSectionActivity = contentActivity.online_test_activity.online_test_section_activities.find(onlineTestSectionActivity => {
          return onlineTestSectionActivity.online_test_section_id == this.onlineTestSectionId;
        });
      // }

      if (!onlineTestSectionActivity) {
        let others = {
          testLanguageCode: this.testLanguageCode,
          displayRuby: this.displayRuby,
          choiceArrangement: this.choiceArrangement,
          remainingTime: this.$parent.getRemainingTime()
        }
        let data = await API.fetchOnlineTestSectionActivity(this.onlineTestSectionId, this.mode, others);
        contentActivity = data.content_activity;
        onlineTestSectionActivity = data.online_test_section_activity;
      }

      API.LMSSetValue('contentActivity', contentActivity);
      API.LMSSetValue('onlineTestSectionActivity', onlineTestSectionActivity);

      let temporarilySaveData = onlineTestSectionActivity && onlineTestSectionActivity.progress_text && onlineTestSectionActivity.progress_text.temporarily_save_data
        ? onlineTestSectionActivity.progress_text.temporarily_save_data
        : null;

      if (temporarilySaveData) {
        await new Promise(resolve => {
          swal({
            content: $(`<div>${this.$t('temporarilySavedDataFound')}</div>`)[0],
            buttons: {
              cancel: {
                text: "データを削除する",
                value: false,
                visible: true,
                className: "red white-text",
                closeModal: true,
              },
              confirm: {
                text: "続ける",
                value: true,
                visible: true,
                className: "",
                closeModal: true
              }
            },
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then(async response => {
            if (response) {
              new Promise(resolveInside => {
                this.$parent.restoreFromSaveData = true;
                this.$parent.saveData = temporarilySaveData;
                resolveInside();
              }).then(response => {
                // this.startFromSaveData();
                resolve();
              });
            } else {
              await API.removeTemporarilySavedData(this.onlineTestSectionId);
              resolve();
            }
          });
          $('.swal-button--cancel').html(this.$t('deleteData'));
          $('.swal-button--confirm').html(this.$t('useTemporarilySavedData'));
        });
      }

      await this.fetchQuestions();
      this.setQuestionGroup();
      this.handleAudios();

      if (this.restoreFromSaveData) {
        this.openedTestDirections = this.saveData.openedTestDirections;
      }

      this.isFetchingData = false;

      await this.startTest();

      if (this.testStarted) {
        this.$parent.startTimer(this.restoreFromSaveData ? this.saveData.remainingTime : this.onlineTestSection.time);
        this.startOverallAudio(this.restoreFromSaveData ? this.saveData.playingAudioCurrentTime : 0);
        if (this.restoreFromSaveData && this.$refs.question.selectedQuestions[this.playingQuestionIndex].audioStarted && this.$refs.question.selectedQuestions[this.playingQuestionIndex].audioStarted[this.playingAudioIndex])
          this.$refs.question.startAudio(this.playingQuestionIndex, this.playingAudioIndex, this.saveData.playingAudioCurrentTime);

        let self = this;
        $(document).ready(() => {
          $('.side-button').mouseenter(function (event) {
            let name = $(this).attr("name");
            setTimeout(() => {
              self.isSideButtonAlive[name] = true;
            }, 500);
          });
        });
        $(document).ready(() => {
          $('.side-button').mouseleave(function (event) {
            let name = $(this).attr("name");
            self.isSideButtonAlive[name] = false;
          });
        });
      } else this.$emit('backToMenuPage');

      $('html').scrollTop(0);
      
      $(document).ready(() => {
        this.setSaveData();
        this.saveDataInterval = setInterval(() => {
          this.setSaveData();
        }, 5000, self = this);
      });
    },
    getTestPartOfQuestion(question) {
      return this.testParts.find(testPart => {
        return testPart.id === question.test_part_id;
      });
    },
    swalBackToMenuPage(event) {
      this.$emit('close');
    },
    getUrl(path) {
      return helpers.getUrl(path);
    },
    async fetchQuestions() {
      let onlineTestSectionActivity = API.LMSGetValue('onlineTestSectionActivity');
      if (this.restoreFromSaveData && !this.saveData.isFetchingQuestions) {
        let questionData = JSON.parse(onlineTestSectionActivity.progress_text.question_data);
        this.questions = await API.fetchQuestionsById(questionData.question_ids, questionData.online_test_question_ids);
        this.questionTagCounts = this.saveData.questionTagCounts;
        this.questionTags = this.saveData.questionTags;
      } else if (onlineTestSectionActivity && onlineTestSectionActivity.progress_text && onlineTestSectionActivity.progress_text.question_data) {
        let questionData = JSON.parse(onlineTestSectionActivity.progress_text.question_data);
        this.questions = await API.fetchQuestionsById(questionData.question_ids, questionData.online_test_question_ids);
        this.questionTagCounts = questionData.question_tag_counts;
        this.questionTags = questionData.question_tags;
      } else {
        let questionData = await API.fetchQuestions(this.onlineTestSectionId, this.$parent.mode, this.choiceArrangement);
        this.questions = questionData.questions;
        this.questionTagCounts = questionData.question_tag_counts;
        this.questionTags = questionData.question_tags;
      }
      this.$parent.resetTestPartQuestionAmount(this.questions);
    },
    handleAudios() {
      let self = this;
      this.questions.forEach((question) => {
        let questionAudioArray = [];
        if (question && question.question_audios) {
          question.question_audios.forEach((audio) => {
            questionAudioArray.push(this.getAudioUrl(audio));
            self.totalAudioNum += 1;
          });
          if (questionAudioArray.length > 0) self.audioUrlList.push(questionAudioArray);
        }
      });
      if (!this.isIOS && this.audioUrlList.length > 0) this.preloadAudioRecursively(0, 0);
    },
    preloadAudioRecursively(questionIndex, audioIndex) {
      let url = helpers.getUrl(this.audioUrlList[questionIndex][audioIndex]);
      let self = this;
      let player = new Audio(url);
      player.addEventListener('canplaythrough', function (e) {
        self.loadedAudioNum += 1;
        if (audioIndex < self.audioUrlList[questionIndex].length - 1) {
          self.preloadAudioRecursively(questionIndex, audioIndex + 1);
        } else if (questionIndex < self.audioUrlList.length - 1) {
          self.preloadAudioRecursively(questionIndex + 1, 0);
        }
      });
      if (this.isIOS) player.load();
    },
    async startTest() {
      if (this.restoreFromSaveData) {
        if (this.saveData.isResultCommitted) await this.$parent.switchToNextTestSection(this.saveData.onlineTestSectionId, null, 1);
        else if (this.saveData.isFetchingQuestions || !this.saveData.testStarted) await this.showStartPanel();
        else await this.startFromSaveData();
      } else {
        await this.showStartPanel();
      }
    },
    async showStartPanel() {
      // this.answers = Array(this.questions.length).fill('');
      this.$store.dispatch("answersState/setAnswers", Array(this.questions.length).fill(''));
      if (this.mode == 'practice') {
        await swal(this.$t('wantToStartNTest', { n: this.onlineTestSection.name }), {
          buttons: [this.$t('no'), this.$t('yes')],
          closeOnClickOutside: false,
          closeOnEsc: false
        }).then(async response => {
          if (response) {
            this.testStarted = true;
          } else {
            this.testStarted = false;
            this.backToMenuPage();
          }
        });
      } else {
        let title = this.$t('wantToStartNTest', { n: this.onlineTestSection.name });
        if (this.sectionStartTimer) await helpers.swalCountToStart(
          this.restoreFromSaveData && !this.saveData.isFetchingQuestions
            ? this.saveData.sectionStartStandbyTime
            : this.sectionStartStandbyTime,
          title,
          'automaticallyStartAfterNSeconds',
          this.$t('startImmediately'),
          this
        );
        else {
          await swal(
            {
              title,
              text: this.$t('clickButtonToStart'),
              icon: 'warning',
              button: this.$t('start'),
              closeOnClickOutside: false,
              closeOnEsc: false,
            }
          )
        }
        this.testStarted = true;
      }
    },
    async startFromSaveData() {
      // this.answers = this.saveData.answers;
      this.$store.dispatch("answersState/setAnswers", this.saveData.answers);
      this.inQuestionNumber = this.saveData.inQuestionNumber;
      this.playingQuestionIndex = this.saveData.playingQuestionIndex;
      this.playingAudioIndex = this.saveData.playingAudioIndex;
      if (this.commitImmediately)
        await swal({
          text: this.$t('expirationDatePassedPleaseSelectSendingData'),
          buttons: {
            cancel: {
              text: this.$t('deleteData'),
              value: false,
              visible: true,
              className: "red white-text",
              closeModal: true,
            },
            confirm: {
              text: this.$t('sendData'),
              value: true,
              visible: true,
              className: "",
              closeModal: true
            }
          },
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then(response => {
          if (response) this.commit();
          else localStorage.removeItem(this.saveDataName);
        });
      else this.testStarted = true;
    },
    setQuestionGroup() {
      let newSet = new Set(this.questions.map((question) => {
        if (question.question_group_id) {
          return question.question_group_id;
        }
      }));
      newSet.delete(undefined);
      let questionGroupIds = [...newSet];
      questionGroupIds.forEach(questionGroupId => {
        let filteredQuestions = this.questions.filter(question => question.question_group_id === questionGroupId);
        let numberOfQuestion = filteredQuestions.length;
        let firstQuestion = this.questions.findIndex(question => question.id === filteredQuestions[0].id) + 1;
        filteredQuestions.forEach(question => {
          question.question_group.firstQuestion = firstQuestion;
          question.question_group.numberOfQuestion = numberOfQuestion;
        });
      });
    },
    startOverallAudio(currentTime) {
      $(document).ready(() => {
        if (this.$refs['overall-audio']) {
          this.$refs['overall-audio'].currentTime = currentTime;
          this.audioPlay();
          this.$refs['overall-audio'].addEventListener('ended', this.audioEndedHandler, false);
          this.$refs['overall-audio'].addEventListener('pause', this.audioPauseHandler, false);
        }
        setTimeout(() => {
          if ($('.fixed-action-btn')[0]) this.sideMenuButton = $('.fixed-action-btn')[0].M_FloatingActionButton;
        }, 1000);
      });
    },
    async audioPlay() {
      this.$refs['overall-audio'].load();
      if (this.isIOS) {
        try {
          this.$refs['overall-audio'].play();
          await helpers.sleep(3000);
          if (this.$refs['overall-audio'].played.length === 0) throw 'Audio play error';
          this.restartTimer();
        } catch (e) {
          console.log(e);
          this.audioPlayErrorHandler();
        }
      } else {
        try {
          await this.$refs['overall-audio'].play();
          this.restartTimer();
        } catch (e) {
          console.log(e);
          this.audioPlayErrorHandler();
        }
      }
    },
    async commit() {
      this.isResultCommitted = false;
      this.$emit('pauseTimer');
      let result = this.getResult(this.questions, this.answers);
      let response = {};

      response = await API.LMSCommit(
        // this.onlineTestId,
        // [this.onlineTestSection.id],
        [API.LMSGetValue('onlineTestSectionActivity').id],
        this.mode,
        [result],
        { testLanguageCode: this.testLanguageCode, displayRuby: this.displayRuby, choiceArrangement: this.choiceArrangement }
      );

      if (response.success) {
        this.isResultCommitted = true;
        if (this.mode === 'practice') this.$refs.question.stopAudios();
        else if (this.$refs['overall-audio']) {
          this.$refs['overall-audio'].removeEventListener('ended', this.audioEndedHandler);
          this.$refs['overall-audio'].removeEventListener('pause', this.audioPauseHandler);
          this.$refs['overall-audio'].pause();
        }
        if (this.mode === 'practice' || !this.$parent.checkNextTestSection(this.onlineTestSection.id)) {
          clearInterval(this.saveDataInterval);
          localStorage.removeItem(this.saveDataName);
        } else this.setSaveData();
        swal({
          content: $(`<span class="line-height-5">${this.$t('testResultsSent')}</span>`)[0],
          icon: 'success',
          closeOnClickOutside: false,
          closeOnEsc: false,
          timer: 10000
        }).then(async () => {
          if (this.mode === 'test') {
            this.$emit('switchToNextTestSection', this.onlineTestSectionId, response.url);
          } else {
            if (API && API.LMSGetValue('contentQuestionnaireUrl')) {
              await new Promise((resolve, reject) => {
                swal(
                  {
                    icon: "info",
                    content: $(`<span>
                    アンケートのご<ruby>記入<rp>(</rp><rt>きにゅう</rt><rp>)</rp></ruby>をご<ruby>協力<rp>(</rp><rt>きょうりょく</rt><rp>)</rp></ruby><ruby>頂<rp>(</rp><rt>いただ</rt><rp>)</rp></ruby>ければ<ruby>幸<rp>(</rp><rt>さいわ</rt><rp>)</rp></ruby>いでございます。
                        </span>`)[0],
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ['終了', 'アンケートを回答'],
                    timer: 60000
                  }
                ).then((response) => {
                  try {
                    if (response) {
                      let newWindow = window.open(
                        API.LMSGetValue('contentQuestionnaireUrl'),
                        "_blank",
                        "resizable=yes, scrollbars=yes, status=no, toolbar=no, menubar=no, location=no"
                      );
                    }
                    resolve();
                  } catch (e) {
                    resolve();
                  }
                });
                $('.swal-button.swal-button--confirm')[0].innerHTML = this.$t('fillOutQuestionaire');
                $('.swal-button.swal-button--cancel')[0].innerHTML = this.$t('end');
              });
            }
            if (response.url) window.location.href = response.url;
            this.$emit('backToMenuPage');
          }
        });
      } else {
        if (response.message === 'section-activity-created') {
          swal({
            content: $(`<span>${this.$t('testSectionTested')}</span>`)[0],
            icon: 'success',
            buttons: this.mode === 'test' && this.$parent.checkNextTestSection(this.onlineTestSectionId) ? ['次のテストセクションへ', '終了'] : ['', '終了'],
            closeOnClickOutside: false,
            closeOnEsc: false,
            timer: 10000
          }).then((action) => {
            if (action) this.$emit('backToMenuPage');
            else {
              if (this.mode === 'test' && this.$parent.checkNextTestSection(this.onlineTestSectionId)) {
                this.$emit('switchToNextTestSection', this.onlineTestSectionId);
              } else {
                this.$emit('backToMenuPage');
              }
            }
          });
          $('.swal-button.swal-button--confirm')[0].innerHTML = this.$t('end');
          if (this.mode === 'test' && this.$parent.checkNextTestSection(this.onlineTestSectionId)) $('.swal-button.swal-button--cancel')[0].innerHTML = this.$t('nextTestSection');
        } else {
          await new Promise((resolve, reject) => {
            swal({
              content: $('<span>エラーが<ruby>発生<rp>(</rp><rt>はっせい</rt><rp>)</rp></ruby>しました。<ruby>下<rp>(</rp><rt>した</rt><rp>)</rp></ruby>の「<ruby>提出<rp>(</rp><rt>ていしゅつ</rt><rp>)</rp></ruby>」ボタンをクリックし、<ruby>再<rp>(</rp><rt>さい</rt><rp>)</rp></ruby><ruby>提出<rp>(</rp><rt>ていしゅつ</rt><rp>)</rp></ruby>してください。</span>')[0],
              buttons: this.mode === 'test' && this.$parent.checkNextTestSection(this.onlineTestSectionId) ? ['次のテストセクションへ', '提出'] : ['終了', '提出'],
              closeOnClickOutside: false,
              closeOnEsc: false,
            }).then(async response => {
              if (response) await this.commit();
              else {
                if (this.mode === 'test' && this.$parent.checkNextTestSection(this.onlineTestSectionId)) {
                  this.$emit('switchToNextTestSection', this.onlineTestSectionId);
                } else {
                  this.$emit('backToMenuPage');
                }
              }
              resolve();
            });
            if (this.mode === 'test' && this.$parent.checkNextTestSection(this.onlineTestSectionId)) $('.swal-button.swal-button--cancel')[0].innerHTML = this.$t('nextTestSection');
            else $('.swal-button.swal-button--cancel')[0].innerHTML = this.$t('end');
            $('.swal-button.swal-button--confirm')[0].innerHTML = this.$t('submit');
          });
        }
      }

      console.log('success=', response.success);
    },
    getResult() {
      return API.getResult(this.questions, this.answers);
    },
    getAudioUrl(audio) {
      if (!API) {
        return `${window.axios.defaults.baseURL}${audio.path}`;
      } else {
        return audio.path;
      }
    },
    audioEndedHandler() {
      if (this.playingQuestionIndex < this.audioUrlList.length) {
        if (this.playingAudioIndex == this.audioUrlList[this.playingQuestionIndex].length - 1) {
          if (this.playingQuestionIndex == (this.audioUrlList.length - 1)) return;
          this.playingQuestionIndex += 1;
          this.playingAudioIndex = 0;
        } else this.playingAudioIndex += 1;
        $(document).ready(() => {
          this.$refs['overall-audio'].play();
        });
      }
    },
    audioPauseHandler(e) {
      if (e.target.ended !== true) {
        e.srcElement.play();
      }
    },
    audioPlayErrorHandler() {
      this.pauseTimer();
      if (!swal.getState().isOpen)
        swal({
          icon: "error",
          content: $(`<span>${this.$t('badInternetConnectionUnableToGetAudios')}</span><br><br><span class="orange-text">（${this.$t('internetConnectedNormallyToGetAudios')}）</span>`)[0],
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          className: "audio-play-error"
        });
      this.audioPlay();
    },
    backToMenuPage() {
      this.$emit('backToMenuPage');
    },
    switchTimer() {
      this.testPaused ? this.restartTimer() : this.pauseTimer();
      $('.tooltipped.switch-timer').tooltip('close');
      setTimeout(() => {
        if ($('.fixed-action-btn')[0] && $('.fixed-action-btn')[0].M_FloatingActionButton.isOpen)
          $('.tooltipped.switch-timer').tooltip('open');
      }, 500);
    },
    pauseTimer() {
      this.$emit('pauseTimer');
      this.testPaused = true;
    },
    restartTimer() {
      this.$emit('restartTimer');
      this.testPaused = false;
    },
    async temporarilySave() {
      let responseData = await API.temporarilySave(this.getTemporarilySaveData());
      if (responseData.success) swal({
        content: $(`<span>${this.$t('dataTemporarilySaved')}</span>`)[0],
      }); else swal({
        content: $(`<span>${responseData.message ?? this.$t('internetDisconnected')}</span>`)[0],
        icon: "error"
      });
    },
    timeOver() {
      let second = 5;
      let timer;

      swal(
        {
          title: "時間になったため、テストが終了しました。",
          content: $(`<span>${this.$t('automaticallyScoreAfterNSeconds', { n: second })}</span>`)[0],
          icon: "warning",
          button: 'すぐ採点',
          closeOnClickOutside: false,
          closeOnEsc: false,
          timer: second * 1000,
        }
      ).then(() => {
        clearInterval(timer);
        this.commit();
      });

      $('.swal-title').html(this.$t('timeUpToEndTest'));
      $('.swal-button').html(this.$t('scoreImmediately'));

      timer = setInterval(() => {
        if (second < 1) {
          clearInterval(timer);
        } else {
          second -= 1;
          $('.swal-content').html(`<span>${this.$t('automaticallyScoreAfterNSeconds', { n: second })}</span>`);
        }
      }, 1000);
    },
    prev() {
      let questionNumber = (
        this.testPart.code === "p2" ? (
          this.firstQuestionNumberOfCurrPart - 1
        ) : this.currQuestion.question_group ? (
          this.currQuestion.question_group.firstQuestion - 1
        ) : (
          this.inQuestionNumber - 1
        )
      );
      return questionNumber;
    },
    next() {
      if (this.testPart.code === "p2") return this.nextPart();
      let questionNumber =
        this.currQuestion.question_group ? (
          this.currQuestion.question_group.firstQuestion + this.currQuestion.question_group.numberOfQuestion
        ) : (
          this.inQuestionNumber + 1
        )
        ;
      if (questionNumber < this.questions.length)
        return questionNumber;
    },
    prevPart() {
      let self = this;
      let currIndex = this.testParts.findIndex(function (testPart) {
        return testPart.id == self.testPart.id;
      });
      let prevTestPart = this.testParts[currIndex - 1];
      let questionNumber = this.firstQuestionNumberOfCurrPart - prevTestPart.question_amount;
      return questionNumber > 0 ? questionNumber : 1;
    },
    nextPart() {
      let questionNumber = this.firstQuestionNumberOfCurrPart + this.testPart.question_amount;
      return questionNumber < this.questions.length ? questionNumber : this.questions.length;
    },
    getFirstQuestionNumberOfPart(part) {
      let questionNumber = this.questions.findIndex(function (question) {
        return (question && question.test_part_id === part.id);
      }) + 1;
      if (questionNumber === -1) return NaN;
      else return questionNumber;
    },
    togglePopupAnswerSheet() {
      if (this.isQuestionListOpen) return;
      this.isAnswerSheetOpen = !this.isAnswerSheetOpen;
    },
    setSaveData() {
      if (API.LMSGetValue('isTeacher')) return;
      try {
        localStorage.setItem(this.saveDataName, JSON.stringify(this.getSaveData()));
      } catch (e) {
        if (!localStorage.getItem('unsaveData')) {
          swal('ローカルストレージの利用可能容量を超えたため、「テストデータの一時保存と回復機能」がブロックされました。<br>「テストデータの一時保存と回復機能」をご利用される方、ブラウザーキャッシュを一度削除してから、ご利用ください。', {
            icon: "warning"
          });
          $('.swal-text').html(`<span>${this.$t('localStorageExceeded')}<br>${this.$t('deleteBrowswerCache')}</span>`);
          localStorage.setItem('unsaveData', true);
        }
      }
    },
    getSaveData() {
      let data = {
        isFetchingQuestions: this.isFetchingData,
        testStarted: this.testStarted,
        isResultCommitted: this.isResultCommitted,
        sectionStartTimer: this.sectionStartTimer,
        sectionStartStandbyTime: helpers.getSectionStartRemainingTime() ? helpers.getSectionStartRemainingTime() : this.sectionStartStandbyTime,
        privilegeType: this.privilegeType,
        coursePrivilegeTime: API.LMSGetValue('coursePrivilegeTime'),
        mode: this.mode,
        testLanguageCode: this.testLanguageCode,
        displayRuby: this.displayRuby,
        choiceArrangement: this.choiceArrangement,
        onlineTestSectionId: this.onlineTestSectionId,
        questionTagCounts: this.questionTagCounts,
        questionTags: this.questionTags,
        answers: this.answers,
        remainingTime: this.testStarted && this.$parent.getRemainingTime() ? this.$parent.getRemainingTime() : (this.onlineTestSection.time_calculation_method === "share" ? this.$parent.sharedTestTime : this.onlineTestSection.time),
        inQuestionNumber: this.inQuestionNumber,
        playingQuestionIndex: this.playingQuestionIndex,
        playingAudioIndex: this.playingAudioIndex,
        playingAudioCurrentTime: this.$refs['overall-audio']
          ? this.$refs['overall-audio'].currentTime
          : $(`#audio-${this.playingQuestionIndex}-${this.playingAudioIndex}`)[0] && $(`#audio-${this.playingQuestionIndex}-${this.playingAudioIndex}`)[0].currentTime
            ? $(`#audio-${this.playingQuestionIndex}-${this.playingAudioIndex}`)[0].currentTime
            : 0,
        sharedTestTime: this.$parent.sharedTestTime,
        openedTestDirections: this.openedTestDirections,
      };
      return data;
    },
    getTemporarilySaveData() {
      let data = this.getSaveData();
      return data;
    },
    async openDirection() {
      if (!(this.testStarted && this.testDirection)) return;
      let opened = $.inArray(this.testDirection.id, this.openedTestDirections) > -1;
      let self = this;
      if (!opened && $('.direction-modal').length > 0) {
        while (!opened) {
          try {
            $('.direction-modal').modal('open');
            self.openedTestDirections.push(self.testDirection.id);
            opened = $.inArray(self.testDirection.id, self.openedTestDirections) > -1;
          } catch (e) {
            console.log(e);
            await helpers.sleep(500);
          }
        }
      }
    }
  },
  computed: {
    answers() {
      return this.$store.getters['answersState/getAnswers'];
    },
    currQuestion() {
      return this.questions[this.inQuestionNumber - 1];
    },
    firstQuestionNumberOfCurrPart() {
      return this.getFirstQuestionNumberOfPart(this.testPart);
    },
    questionIds() {
      return this.questions.map(question=>question.id);
    },
    testPart() {
      return this.testParts.find(testPart => {
        return testPart.id === this.currQuestion.test_part_id;
      });
    },
    testDirection() {
      return this.testDirections.find(testDirection => {
        return testDirection.id === this.currQuestion.test_direction_id;
      });
    }
  },
  watch: {
    inQuestionNumber() {
      this.isAnswerSheetOpen = false;
    },
  },
  beforeDestroy() {
    if (this.$refs['overall-audio']) {
      this.$refs['overall-audio'].removeEventListener('ended', this.audioEndedHandler);
      this.$refs['overall-audio'].removeEventListener('pause', this.audioPauseHandler);
    }
    clearInterval(this.saveDataInterval);
    localStorage.removeItem('unsaveData');
  }
}
</script>

<style lang="scss" scoped>
.test-started {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.test-container {
  .overall-audio-container {
    display: none;
  }

  max-height: 90%;
  height: 90%;
  display: flex;
  padding: 10px;
  grid-column-gap: 10px;
  align-items: stretch;

  .question-container {
    flex-grow: 1;
  }
}

.left-button,
.right-button {
  width: 0px;
  height: 0px;
  padding: 0;
  display: flex;
  align-items: center;
  border: 80px solid transparent;
  box-shadow: unset;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 12px;
  color: white;
  text-transform: uppercase;
  z-index: 0;
  position: fixed;
  top: 40%;

}


.left-button {
  left: -50px;
  border-right-color: #26A69A;

  span {
    line-height: 1;
    position: absolute;
    left: 15px;
  }
}

.right-button {
  right: -50px;
  border-left-color: #26A69A;

  span {
    line-height: 1;
    position: absolute;
    left: -75px;
  }
}

.left-button.button-disabled {
  border-right-color: #DFDFDF;
}

.right-button.button-disabled {
  border-left-color: #DFDFDF;
}

.button-disabled {
  pointer-events: none;
}

.button-hidden {
  z-index: -1;
}

.short-label {
  display: none;
}

@media only screen and (max-width: 1000px) {

  .left-button,
  .right-button {
    border: 40px solid transparent;
    font-size: 9px;
  }

  .left-button {
    left: -35px;
    border-right-color: #26A69A;

    span {
      left: 12px;
    }
  }

  .right-button {
    right: -35px;
    border-left-color: #26A69A;

    span {
      right: -30px;
    }
  }

  .long-label {
    display: none;
  }

  .short-label {
    display: inline;
  }
}

.fixed-action-btn {
  bottom: 10vh;
  padding: unset;
}

.answerSheet-btn {
  display: none;
}

@media only screen and (max-width: 800px) {
  .hidden-page {
    display: none;
  }

  .answerSheet-container {
    flex-grow: 1;
  }

  .answerSheet-btn {
    display: inline;
    position: fixed;
    left: 2vw;
    bottom: 10vh;
  }
}

@media only screen and (max-width: 600px) {

  .left-button,
  .right-button {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .answerSheet-btn,
  .fixed-action-btn {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}
</style>