<template>
  <div class="footer">
    <a v-if="!$parent.isFirstQuestion" class="waves-effect waves-light btn-large" @click="prev()">
      <i class="material-icons left margin-x-0">chevron_left</i>
      <span class="words" v-html="$t('previous')"></span>
    </a>
    <a class="waves-effect waves-light btn-large modal-trigger" href="#question-list">
      <i class="material-icons left margin-x-0">list</i>
      <span class="words" v-html="$t('questionList')"></span>
    </a>
    <div id="question-list" class="modal bottom-sheet question-list">
      <div class="modal-content row">
        <div class="question-list-close modal-close">
          <a href="#!" class="modal-close grey-text" v-html="$t('close')"></a>
        </div>
        <h4 class="question-list-title" v-html="$t('questionList')"></h4>
        <div class="d-flex row question-list-content single-col col s12 m12">
          <div class="col s12">
            <div class="d-flex row question-list-header">
              <div class="col s4 question-list-grid" v-html="$t('questionNumber')"></div>
              <div class="col s4 question-list-grid status" v-html="$t('status')"></div>
              <div class="col s4 question-list-grid" v-html="$t('answer')"></div>
            </div>
            <div class="question-list-body">
              <div v-for="(question, index) in questions" :key="index" class="d-flex row flex-wrap-nowrap" @click="redirect(index + 1)">
                <div class="col s4 question-list-grid" :class="$parent.answers[index] ? '' : 'no-answer'">
                  {{ index + 1 }}
                </div>
                <div class="col s4 question-list-grid status" :class="$parent.answers[index] ? '' : 'no-answer'" v-html="$parent.answers[index]
                            ? $t('answered')
                            : $t('unanswered')"
                >
                </div>
                <div class="col s4 question-list-grid" :class="$parent.answers[index] ? '' : 'no-answer'">
                  {{ $parent.answers[index] ? $parent.answers[index] : '-' }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex row question-list-content double-cols col s12 m12">
          <div class="col s6">
            <div class="d-flex row question-list-header">
              <div class="col s4 question-list-grid" v-html="$t('questionNumber')"></div>
              <div class="col s4 question-list-grid status" v-html="$t('status')"></div>
              <div class="col s4 question-list-grid" v-html="$t('answer')"></div>
            </div>
            <div class="question-list-body">
              <div v-for="(question, index) in questions" :key="index" v-if="index % 2 == 0" class="d-flex row flex-wrap-nowrap"
                @click="redirect(index + 1)">
                <div class="col s4 question-list-grid" :class="$parent.answers[index] ? '' : 'no-answer'">
                  {{ index + 1 }}
                </div>
                <div class="col s4 question-list-grid status" :class="$parent.answers[index] ? '' : 'no-answer'"
                  v-html="$parent.answers[index]
                            ? $t('answered')
                            : $t('unanswered')"
                >
                </div>
                <div class="col s4 question-list-grid" :class="$parent.answers[index] ? '' : 'no-answer'">
                  {{ $parent.answers[index] ? $parent.answers[index] : '-' }}
                </div>
              </div>
            </div>
          </div>
          <div class="col s6">
            <div class="d-flex row question-list-header">
              <div class="col s4 question-list-grid" v-html="$t('questionNumber')"></div>
              <div class="col s4 question-list-grid status" v-html="$t('status')"></div>
              <div class="col s4 question-list-grid" v-html="$t('answer')"></div>
            </div>
            <div class="question-list-body">
              <div v-for="(question, index) in questions" :key="index" v-if="index % 2 != 0" class="d-flex row flex-wrap-nowrap"
                @click="redirect(index + 1)">
                <div class="col s4 question-list-grid" :class="$parent.answers[index] ? '' : 'no-answer'">
                  {{ index + 1 }}
                </div>
                <div class="col s4 question-list-grid status" :class="$parent.answers[index] ? '' : 'no-answer'"
                  v-html="$parent.answers[index]
                            ? $t('answered')
                            : $t('unanswered')"
                >
                </div>
                <div class="col s4 question-list-grid" :class="$parent.answers[index] ? '' : 'no-answer'">
                  {{ $parent.answers[index] ? $parent.answers[index] : '-' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a v-if="!$parent.isLastQuestion" class="waves-effect waves-light btn-large" @click="next()">
      <span class="words" v-html="$t('next')"></span>
      <i class="material-icons right margin-x-0">chevron_right</i>
    </a>
    <a class="waves-effect waves-light btn-large red" @click="submit">
      <i class="material-icons left margin-x-0">mail</i>
      <span class="words" v-html="$t('allFinished')"></span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    questions: Array,
  },
  created() {
    $(document).ready(() => {
      M.AutoInit();
      this.setListeners();
    });
  },
  methods: {
    prev() {
      this.$parent.inQuestionNumber = Math.max(this.$parent.$refs.question.questionNumbers[0] - 1, 1);
    },
    next() {
      this.$parent.inQuestionNumber = Math.min(this.$parent.$refs.question.questionNumbers[this.$parent.$refs.question.questionNumbers.length - 1] + 1, this.$parent.questions.length);
    },
    redirect(questionNumber) {
      this.$parent.inQuestionNumber = questionNumber;
      $('.modal').modal('close')
    },
    submit() {
      swal('テスト結果を提出してよろしいですか？', {
        buttons: [this.$t('no'), this.$t('yes')],
        icon: "warning"
      }).then(response => {
        if (response) this.$emit('submit');
      });
      $('.swal-text').html(`<div>${this.$t('wantToSubmitResults')}</div><div class="d-flex justify-content-center"><span>${this.$t('unansweredQuestionNumber', {n:this.unansweredQuestionAmount})}</span></div>`);
    },
    setListeners() {
      let test = this.$parent;
      $('#question-list').modal({
        onOpenStart: function() {
          test.isQuestionListOpen = true;
          test.isAnswerSheetOpen = false;
        },
        onCloseEnd: function() {
          test.isQuestionListOpen = false;
        },
      });
    }
  },
  computed: {
    unansweredQuestionAmount() {
      return this.$parent.answers.filter(answer => {
        return !answer;
      }).length;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>