export default {
  namespaced: true,
  state: {
    answers: []
  },
  mutations: {
    SET_ANSWERS(state, data) {
      state.answers = data;
    },
    SET_ANSWER(state, data) {
      state.answers[data.key] = data.value;
      state.answers = JSON.parse(JSON.stringify(state.answers));
    },
  },
  actions: {
    setAnswers({ commit, state }, payload) {
      commit('SET_ANSWERS', payload);
    },
    setAnswer({ commit, state }, payload) {
      commit('SET_ANSWER', payload);
    },
  },
  getters: { 
    getAnswers(state) {
      return state.answers;
    },
  }
}
