<template>
  <div class="question-container" :class="{ 'hide-ruby': !displayRuby }">
    <div class="question-header">
      <div class="question-number">
        <span class="material-icons margin-right-5">quiz</span>
        <span>Q&nbsp;{{ questionNumbers[0] }}{{ mergeGroupQuestions ? `-${Math.max(...questionNumbers)}` : ''
          }}&nbsp;/&nbsp;{{ questionTotalAmount }}</span>
      </div>
    </div>
    <div v-if="testDirection" class="direction-botton" :class="{ 'z-index-minus-1': $parent.isQuestionListOpen }"><a
        class="waves-effect waves-light btn modal-trigger" href="#direction-modal">{{ $t('questionBreakdown') }}</a>
    </div>
    <div v-if="mergeGroupQuestions && question && question.question_group" class="question-group-text"
      v-html="question.question_group.text"></div>
    <div v-if="selectedQuestions.length > 0">
      <div v-if="question" v-for="(question, questionIndex) in selectedQuestions" :key="questionIndex">
        <div class="question-sub-header">
          <div class="question-index">
            <span v-if="question.display_number_2">{{ question.display_number_1 }}</span>
            <span v-if="question.display_number_2">　{{ question.display_number_2 }}</span>
            <span v-if="mode === 'practice'" v-for="(questionTestElement, index) in question.question_test_elements"
              :key="index" class="test-element"
              :class="{ 'margin-left-0': !(question.display_number_1 || question.display_number_2) }"
              v-html="questionTestElement.test_element.text">
            </span>
          </div>
          <div v-if="mode == 'practice'" class="audio-container">
            <audio v-for="(audio, index) in question.question_audios" :id="`question-text-audio-${questionIndex}-${index}`"
              :key="index" ref="audio" class="audio" :src="getUrl(audio.path)" controls controlsList="nodownload"
              type="audio/mpeg" preload="auto" />
          </div>
          <div v-if="mode === 'test' && $parent.testCode === 'jft-basic'" class="audio-container">
            <div v-for="(audio, index) in question.question_audios">
              <div v-if="question.audioPlayedTimes[index] < 2">
                <div v-if="!question.audioStarted[index]" class="jft-test-audio-frame">
                  <div class="button cursor-pointer" @click="startAudio(questionIndex, index)">
                    <span class="text"><i class="font-15 material-icons">play_circle_filled</i><span
                        v-html="$tc('playRemainingTimes', 2 - question.audioPlayedTimes[index], { n: 2 - question.audioPlayedTimes[index] })" />
                    </span>
                  </div>
                </div>
                <div v-else>
                  <div class="jft-test-audio-frame"><span class="text" v-html="$t('playing')"></span></div>
                  <div class="progress">
                    <div class="indeterminate"></div>
                  </div>
                </div>
                <audio :id="`question-text-audio-${questionIndex}-${index}`" :key="index" ref="audio" class="audio"
                  :src="getUrl(audio.path)" controls controlsList="nodownload" type="audio/mpeg" preload="auto"
                  hidden />
              </div>
              <div v-else class="jft-test-audio-frame">
                <span class="text" v-html="$t('reachedMaximum')"></span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!mergeGroupQuestions" class="question-group-text">
          <span v-if="question.question_group" v-html="question.question_group.text"></span>
        </div>
        <div class="question-text" v-html="API.translation(question, question.language.alias, testLanguageCode)">
        </div>
        <div class="image-container">
          <img v-for="(image, index) in question.question_images" :key="index" :ref="`image_${index}`" class="image"
            :src="getUrl(image.path)" />
        </div>
        <div class="choices">
          <form action="#">
            <label v-for="(questionChoice, index) in question.question_choices" :key="index"
              :for="`question${questionNumbers[questionIndex]}-choice${index + 1}`">
              <p :class="{ 'correct': showCorrectAnswer && questionChoice.is_correct }">
                <input :id="`question${questionNumbers[questionIndex]}-choice${index + 1}`"
                  :name="`question${questionNumbers[questionIndex]}-choice${index + 1}`" type="radio" :value="index + 1"
                  class="input" v-model="answers[questionIndex]" :disabled="showCorrectAnswer"/>
                <span>{{ index + 1 }}.&nbsp;&nbsp;</span>
                <span v-html="API.translation(questionChoice, question.language.alias, testLanguageCode)"></span>
              </p>
            </label>
          </form>
        </div>
        <div v-if="question.question_type_id == 4" class="essay d-flex row flex-wrap-wrap">
          <span class="text-right col s12">{{ calculateWords(answers[questionIndex]) }}/{{ question.options &&
    question.options.words_limit ?
    question.options.words_limit : '--'
            }}</span>
          <textarea class="col s12" v-model="answers[questionIndex]" @input="checkWordLimit"></textarea>
        </div>
        <div v-if="showCorrectAnswer" class="explanation">
          <!-- <div class="judgement" :class="{ 'correct': checkCorrectAnswer() }"><span>{{ checkCorrectAnswer() ? "〇" : "☓"
              }}</span></div> -->
          <div v-show="!isTeacher && checkCorrectAnswer()" class="judgement correct">
            <i class="fa-solid fa-circle-check fa-bounce"></i>
          </div>
          <div v-show="!isTeacher && !checkCorrectAnswer()" class="judgement">
            <i class="fa-solid fa-circle-xmark fa-bounce"></i>
          </div>
          <div class="section-category" v-if="questionExplanations.script && questionExplanations.script.text">
            <div class="pb-3">
              <h5 class="margin-bottom-10">
                <span class="point_brr">
                  <span data-icon="O" class="icon"></span>
                  <span v-html="$t('script')"></span>
                </span>
                <template v-if="question.question_audios && question.question_audios.length > 0 && !isMobile">
                  <audio v-for="(audio, index) in question.question_audios" :id="`script-audio-${questionIndex}-${index}`"
                    :key="index" ref="audio" class="audio" :src="getUrl(audio.path)" controls controlsList="nodownload"
                    type="audio/mpeg" preload="auto" />
                </template>
              </h5>
              <template v-if="question.question_audios && question.question_audios.length > 0 && isMobile">
                <audio v-for="(audio, index) in question.question_audios" :id="`script-audio-${questionIndex}-${index}`"
                  :key="index" ref="audio" class="audio width-percentage-100" :src="getUrl(audio.path)" controls controlsList="nodownload"
                  type="audio/mpeg" preload="auto" />
              </template>
            </div>
            <div>
              <span class="mt-3" v-html="questionExplanations.script.text"></span>
            </div>
          </div>
          <div class="section-category"
            v-if="questionExplanations.description && questionExplanations.description.text">
            <div class="pb-3">
              <h5>
                <span class="point_brr">
                  <span data-icon="X" class="icon"></span>
                  <span v-html="$t('description')"></span>
                </span>
              </h5>
            </div>
            <div>
              <div>
                <span class="mt-3" v-html="questionExplanations.description.text"></span>
              </div>
            </div>
          </div>
          <div class="section-category" v-if="questionExplanations.word && questionExplanations.word.text">
            <div class="pb-3">
              <h5>
                <span class="point_brr">
                  <span data-icon="T" class="icon"></span>
                  <span v-html="$t('word')"></span>
                </span>
              </h5>
            </div>
            <div>
              <div>
                <span class="mt-3" v-html="questionExplanations.word.text"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-data-warning" v-else v-html="$t('relevantDataNotFound')"></div>
    </div>
    <div v-if="testDirection" id="direction-modal" class="direction-modal modal">
      <div class="modal-content" v-html="testDirection.text"></div>
    </div>
  </div>
</template>

<script>
import helpers from '../../js/utils/helpers';

export default {
  props: {
    testLevelCode: String,
    questions: Array,
    question: Object,
    questionNumber: Number,
    questionTotalAmount: Number,
    mode: String,
    testLanguageCode: String,
    testDirection: Object,
    displayRuby: Boolean,
    groupQuestionDisplayMethod: String,
    correctAnswerDisplay: String
  },
  data() {
    return {
      API,
      questionNumbers: [],
      selectedQuestions: [],
      answers: [],
      backupedText: '',
      isGroupQuestion: false,
      isMobile: false,
    }
  },
  created() {
    this.init();
    this.checkIsMobile();
    let self = this;
    window.addEventListener('resize', function (event) {
      self.checkIsMobile();
    });
  },
  mounted() {
    $('audio').on('play', (e) => {
      for (let audio of this.$refs.audio) {
        if (audio.id === e.target.id) continue;
        audio.pause();
      }
    });
  },
  methods: {
    checkIsMobile() {
      this.isMobile = window.innerWidth < 500;
    },
    init() {
      this.isGroupQuestion = this.question && this.question.question_group_id > 0;
      if (this.mergeGroupQuestions) {
        let i = 0;
        this.selectedQuestions = this.questions.filter((question, index) => {
          if (question.question_group_id == this.question.question_group_id) {
            let questionNumber = index + 1;
            this.questionNumbers.push(questionNumber);
            this.answers[i] = this.$parent.answers[questionNumber - 1];
            i++;
            return true;
          }
        });
      } else {
        this.selectedQuestions = [this.question];
        this.questionNumbers.push(this.questionNumber);
        this.answers[0] = this.$parent.answers[this.questionNumber - 1];
      }
      this.$parent.isFirstQuestion = this.questionNumbers[0] <= 1;
      this.$parent.isLastQuestion = this.questionNumbers[this.questionNumbers.length - 1] >= this.questionTotalAmount;

      let self = this;
      $(document).ready(async function () {
        $('#direction-modal').modal();
        await helpers.sleep(500);
        self.$parent.openDirection();
      });
    },
    getUrl(path) {
      return helpers.getUrl(path);
    },
    checkCorrectAnswer() {
      let isCorrect = true;
      let choices = this.question.question_choices;
      for (let i = 0; i < choices.length; i++) {
        let choice = choices[i];
        if ((choice.is_correct && !($.inArray(i + 1, this.answers) > -1))
          || (!choice.is_correct && ($.inArray(i + 1, this.answers) > -1))) isCorrect = false;
      }
      return isCorrect;
    },
    checkWordLimit(e) {
      let textLength = this.calculateWords(e.target.value);
      if (textLength > this.question.options.words_limit) this.answer = this.backupedText;
      this.backupedText = this.answer;
    },
    calculateWords(text) {
      let textLength = text.replace(/\s+/g, '').length;
      return textLength;
    },
    startAudio(questionIndex, audioIndex, currentime = 0) {
      $(`#audio-${questionIndex}-${audioIndex}`)[0].currentTime = currentime;
      $(`#audio-${questionIndex}-${audioIndex}`)[0].play();
      let self = this;
      $(`#audio-${questionIndex}-${audioIndex}`)[0].addEventListener('pause', function (evt) {
        self.selectedQuestions[questionIndex].audioStarted[audioIndex] = false;
        self.selectedQuestions[questionIndex].audioPlayedTimes[audioIndex] += 1;
      });
      self.selectedQuestions[questionIndex].audioStarted[audioIndex] = true;
      self.$parent.playingQuestionIndex = questionIndex;
      self.$parent.playingAudioIndex = audioIndex;
    },
    stopAudios() {
      if (!this.$refs.audio) return;
      for (let audio of this.$refs.audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    }
  },
  computed: {
    mergeGroupQuestions() {
      if (!(this.question && this.question.question_group && this.question.question_group.options)) return false;
      let groupQuestionDisplayMethod = this.question.question_group.options.group_question_display_method;
      return this.isGroupQuestion && (groupQuestionDisplayMethod === 'same-page' || (!groupQuestionDisplayMethod && this.groupQuestionDisplayMethod === 'same-page'));
    },
    questionExplanations() {
      let json = {};
      if (this.question.question_explanations) {
        this.question.question_explanations.forEach(questionExplanation => {
          json[questionExplanation.type] = questionExplanation;
        });
      }
      return json;
    },
    isTeacher() {
      return API.LMSGetValue('isTeacher');
    },
    showCorrectAnswer() {
      return !!(this.isTeacher || (this.correctAnswerDisplay === 'show_when_question_answered' && this.answers[0]));
    }
  },
  watch: {
    answers() {
      let answers = [].concat(this.$parent.answers);
      this.questionNumbers.forEach((questionNumber, index) => {
        answers[questionNumber - 1] = this.answers[index];
      });
      this.$parent.answers = answers;
    }
  },
  beforeDestroy() {
    $('audio').off();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.question-container {
  padding-bottom: 15vh;

  .question-header,
  .question-sub-header {

    .question-number {
      text-align: left;
      display: flex;
      align-items: center;
      margin-top: 2vh;
      margin-bottom: 2vh;
      width: 100%;

      span {
        color: #ef5350;
        font-size: 2.5rem;

        @media only screen and (min-width: 601px) {
          font-size: 3vw;
        }
      }
    }

    .question-index {
      margin: 2vh 0;

      .test-element {
        color: white;
        background-color: #ef5350;
        border-radius: 5px;
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

    .audio-container {
      display: flex;
      align-items: center;
      margin-top: 2vh;
      margin-bottom: 2vh;

      .audio {
        height: 3vh;
      }
    }

    .jft-test-audio-frame {
      background: linear-gradient(45deg, rgba(0, 153, 68, .5) 0, rgba(0, 153, 68, .5) 25%, transparent 25%, transparent 50%, rgba(0, 153, 68, .5) 50%, rgba(0, 153, 68, .5) 75%, transparent 75%, transparent);
      background-size: 10px 10px;
      border-radius: 10px;
      border: 1px solid orange;
      padding: 0.5rem;
      min-width: 200px;

      .text {
        color: #ff9800 !important;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .question-text {
    text-align: left;
    margin-top: 2em;
    line-height: 2;
  }

  .choices {
    label {
      text-align: left;
      font-size: 1.2rem;

      @media screen and (min-width: 1001px) {
        font-size: 1.2vw;
      }

      &>p {
        display: flex;
        align-items: baseline;
        color: black;
        border: 1px solid rgb(221, 220, 220);
        border-radius: 5px;
        cursor: pointer;
        padding-top: 2vh;
        padding-bottom: 2vh;

        padding: {
          left: 2rem;
          right: 2rem;
        }

        input {
          position: unset;
        }
      }
    }

    margin-top: 2em;
    margin-bottom: 2em;
  }

  .essay {
    textarea {
      height: unset;
      min-height: 20rem;
    }
  }

  .no-data-warning {
    font-size: 2rem;
    line-height: 10;
  }
}
</style>

<style lang="scss">
.jlpt-campaign {
  .jlpt-campaign-hot-spring {
    background-image: url('../../../public/assets/images/hot_spring.png');
  }
}
</style>