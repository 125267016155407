<template>
  <div class="answerSheet-container" :class="hide ? 'hidden-page' : ''">
    <div class="answerSheet-header">
      <div class="answerSheet-partName">
        <span> {{ testPart.name }}&nbsp;&nbsp; </span>
      </div>
    </div>
    <!-- <form action="#" v-if="question" class="choices" ref="choices"> -->
    <form v-if="question" class="choices" ref="choices">
      <table>
        <tr class="sticky-label" :class="{'z-index-0' : $parent.isQuestionListOpen}">
          <th></th>
          <th v-for="(column, columnIndex) in (question.question_choices.length)" :key="columnIndex">
            {{ String.fromCharCode(64 + column) }}
          </th>
        </tr>
        <tr v-for="(row, rowIndex) in testPart.question_amount" :key="rowIndex" :class="{'z-index-minus-1': $parent.isQuestionListOpen}">
          <td> {{ questions[firstQuestionNumberOfCurrPart + row - 2].display_number_1 }} </td>
          <td v-for="index in question.question_choices.length" :key="index">
            <label :for="`question${firstQuestionNumberOfCurrPart + row - 1}-choice${index}`">
              <input :id="`question${firstQuestionNumberOfCurrPart + row - 1}-choice${index}`"
                :name="`question${firstQuestionNumberOfCurrPart + row - 1}-choice${index}`" type="radio" :value="index"
                v-model="answers[firstQuestionNumberOfCurrPart + row - 2]" :set="answersInSet = answersState"/> <span></span>
            </label>
          </td>
        </tr>
      </table>
    </form>
    <div class="no-data-warning" v-else>該当問題データが見つかりません。</div>
  </div>
</template>

<script>
export default {
  props: {
    initAnswers: Array,
    questions: Array,
    question: Object,
    questionNumber: Number,
    numberOfChoices: Number,
    firstQuestionNumberOfCurrPart: Number,
    testPart: Object,
    hide: Boolean,
  },

  data() {
    return {
      answers: [],
    }
  },
  created() {
    this.answers = this.$store.getters['answersState/getAnswers'];
    // this.$store.dispatch("answersState/setAnswers", this.initAnswers);
  },
  watch: {
    "testPart.code"() {
      if (this.$refs['choices']) {
        this.$refs['choices'].scrollTop = 0;
        this.$refs['choices'].scrollLeft = 0;
      }
    },
    answers(value) {
      this.$store.dispatch("answersState/setAnswers", value);
    }
  },
  computed: {
    answersState() {
      let answersState = this.$store.getters['answersState/getAnswers'];
      this.answers = answersState;
      return answersState;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.answerSheet-container {
  max-height: 100%;
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
  min-width: 300px;
  align-content: flex-start;
  font-size: 1rem;

  .answerSheet-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .answerSheet-partName {
      text-align: left;
      display: flex;
      margin-top: 1vh;
      margin-bottom: 1vh;

      span {
        color: #ef5350;
        font-size: 2rem;
      }
    }

    .answerSheet-button {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;

      a {
        z-index: 0;
      }
    }
  }

  .choices {
    max-height: 90%;
    width: 100%;
    border: dotted 1px grey;
    align-self: center;
    overflow-y: auto;

    table {
      height: 100%;

      label {
        display: flex;
        justify-content: center;
      }

      input {
        position: unset;
      }

      tr {
        position: relative;
        // z-index: -1;
      }

      th, td {
        text-align: center;
      }
    }

    .sticky-label {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      background: white;
      z-index: 1;
    }
  }

  .no-data-warning {
    font-size: 2rem;
    line-height: 10;
  }
}

@media only screen and (max-width: 600Px) {
  .answerSheet-container {

    .answerSheet-header {
      justify-content: space-around;

      .answerSheet-button {
        justify-content: space-around;
      }
    }
  }
}
</style>