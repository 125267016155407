import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import store from './js/store';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import i18n from './js/locales';
// import 'materialize-css/dist/css/materialize.min.css';
import '@fortawesome/fontawesome-free/js/all';

import './online-test-api/index';

Vue.config.productionTip = false;

window.axios = axios;
window.swal = swal;
window.Swal = Swal;

window.$ = window.jQuery = require('jquery');
window.M = require('materialize-css');

new Vue({
  render: h => h(App),
  i18n,
  store
}).$mount('#app');