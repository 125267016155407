<template>
  <div id="app">
    <Dashboard v-if="apiSet" />
  </div>
</template>

<script>
import Dashboard from './components/Dashboard.vue'
// import M from 'materialize-css'

export default {
  name: 'App',
  components: {
    Dashboard
  },
  data() {
    return {
      apiSet: false
    }
  },
  created() {
    document.addEventListener('contextmenu', function (e) {
      e.preventDefault();
    });
    document.addEventListener('selectstart', function (e) {
      e.preventDefault();
    });
    let onlineTestAPI = null;
    try {
      onlineTestAPI = window.parent.onlineTestAPI;
    } catch (e) {
      onlineTestAPI = window.onlineTestAPI;
    }
    window.API = onlineTestAPI;
    // if (!window.API) window.axios.defaults.baseURL = 'https://stg.ask-online.net'; // Base URL for local test.
    if (!window.API) window.axios.defaults.baseURL = 'https://ask-online.net'; // Base URL for local test.
    this.apiSet = true;
  },
  mounted() {
    M.AutoInit();
    try {
      console.log(window.parent.onlineTestAPI);
    } catch (e) {}
    // if (!window.parent.onlineTestAPI) $('#app').css('overflow-y', 'auto'); // Online for local environment.
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, YuMincho;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // overflow-y: auto;

  &.background-image {
    background: url(../public/assets/images/background_image.png) no-repeat right bottom;
    background-size: 40%;
  }
}
</style>
